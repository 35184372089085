import { observable, action, makeObservable } from 'mobx'

class Meta {
  isLoading = false
  loaderText = ''
  isConfirmModal = false
  confirmModalText = ''
  isConfirmed = false
  confirmButtonArgs = []
  isMapLoaded = null
  addressLatLng = null
  address = null
  orderConfirmedModal = false
  showAlert = false
  alertMessage = ''
  showSpinButton = false
  isServerDownModal = false
  isVersionUpgradeModal = false
  isOnline = true
  isMenuOpen = false

  constructor() {
    makeObservable(this, {
      isMapLoaded: observable,
      addressLatLng: observable,
      address: observable,
      isLoading: observable,
      showAlert: observable,
      alertMessage: observable,
      loaderText: observable,
      isConfirmModal: observable,
      confirmModalText: observable,
      isConfirmed: observable,
      confirmButtonArgs: observable,
      orderConfirmedModal: observable,
      isServerDownModal: observable,
      isVersionUpgradeModal: observable,
      isOnline: observable,
      isMenuOpen: observable,
      setIsMapLoaded: action,
      setAddressLatLng: action,
      setAddress: action,
      setLoader: action,
      setConfirmModal: action,
      setIsConfirmed: action,
      setConfirmButtonArgs: action,
      setOrderConfirmedModal: action,
      setShowAlert: action,
      setIsOnline: action,
      setIsMenuOpen: action
    })
  }

  setLoader(state, text = '') {
    this.isLoading = state
    this.loaderText = text
  }

  setShowAlert(state, message = '') {
    this.showAlert = state
    this.alertMessage = message
  }

  setConfirmModal(state, text = '') {
    this.isConfirmModal = state
    this.confirmModalText = text
  }

  setIsConfirmed(state) {
    this.isConfirmed = state
  }

  setConfirmButtonArgs(args) {
    this.confirmButtonArgs = args
  }

  setIsMapLoaded(value) {
    this.isMapLoaded = value
  }

  setAddressLatLng(lat, lng) {
    this.addressLatLng = { lat: lat, lng: lng }
  }

  setAddress(value) {
    this.address = value
  }

  setOrderConfirmedModal(state) {
    this.orderConfirmedModal = state
  }

  setShowSpinButton(state) {
    this.showSpinButton = state
  }

  setServerDownModal(state) {
    this.isServerDownModal = state
  }

  setVersionUpgradeModal(state) {
    this.isVersionUpgradeModal = state
  }
  
  setIsOnline(state) {
    this.isOnline = state
  }

  setIsMenuOpen(state) {
    this.isMenuOpen = state
  }
}

export default new Meta()
