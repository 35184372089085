import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getServices = () => {
  const query = () => {
    return fetch(`${API_URL}/master/services`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const createServices = (serviceIds, subServices, customServices) => {
  const query = () => {
    return fetch(`${API_URL}/master/services`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        service_ids: serviceIds,
        sub_services: subServices,
        custom_services: customServices
      })

    })
  }

  return backOffCall(query)
}

export {
  getServices,
  createServices
}
