import { makeObservable, observable, action } from 'mobx'
import { User, userProps } from 'store/user.js'
import meta from 'store/meta'

class Master extends User {
  profile = null
  phone = null
  currency = null
  orders = []
  currentOrder = null
  blocked = null
  approved = null
  navigate = null
  referralProgram = null
  referrals = []
  tasks = []
  kycs = []
  needPaymentCheck = false

  constructor() {
    super()
    makeObservable(this, {
      ...userProps,
      phone: observable,
      currency: observable,
      profile: observable,
      orders: observable,
      currentOrder: observable,
      referralProgram: observable,
      tasks: observable,
      referrals: observable,
      needPaymentCheck: observable,
      kycs: observable,
      setMasterData: action,
      addOrder: action,
      removeOrder: action,
      setOrders: action,
      setCurrentOrder: action,
      isSetUp: action,
      setReferralProgram: action,
      setReferrals: action,
      setTasks: action,
      setKycs: action
    })
  }

  setMasterData(data) {
    this.phone = data.phone_number
    this.currency = data.currency_code
    this.profile = data.profile
    this.blocked = data.blocked
    this.approved = data.approved
    this.handleFCMToken()
  }

  setProfile(profile) {
    this.profile = profile
  }

  isLoggedIn() {
    return super.isLoggedIn('master')
  }

  isSetUp() {
    return true
    // return !!this.balance
  }

  setNavigate(navigate) {
    this.navigate = navigate
  }

  navigateTo(path = '/') {
    if (this.navigate) {
      this.navigate(path)
    }
  }

  addOrder(order) {
    this.setOrders([order, ...this.orders])
  }

  removeOrder(order) {
    let orders = this.orders.filter((obj) => {
      return obj['id'] !== order.id
    })
    this.setOrders(orders)
  }

  setOrders(orders) {
    this.orders = orders
  }

  setCurrentOrder(order) {
    this.currentOrder = order
  }

  setReferralProgram(data) {
    this.referralProgram = data
  }

  setReferrals(data) {
    this.referrals = data
  }

  setTasks(data) {
    this.tasks = data
  }

  setKycs(data) {
    this.kycs = data
  }

  setNeedPaymentCheck(data) {
    this.needPaymentCheck = data
  }

  get serviceIds() {
    return this.services.map(s => s.id)
  }

  get isKycApproved() {
    return this.kycs.some(kyc => kyc.approved === true)
  }
}

export default new Master()
