const masks = [
  {
    countryCode: 'UA',
    phoneMask: '+380(99)999-9999'
  },
  {
    countryCode: 'PL',
    phoneMask: '+48-99-99-99-99'
  }
]

const defaultPhoneMask = '+380 (99) 99 99 999'

const getMask = (countryCode) => {
  let el = masks.find(el => el.countryCode === countryCode)
  return el ? el.phoneMask : defaultPhoneMask
}

const onlyDigits = (value) => {
  return value.replace(/\D/g, '')
}

export default {
  getMask,
  onlyDigits
}
