import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { getAll as getAllSpecializations } from 'api/specialization.js'
import s from 'styles/Service.module.css'
import { useNavigate } from 'react-router-dom'
import company from 'store/company'
import master from 'store/master'
import { useTranslation } from 'react-i18next'
import loginBack from 'images/login-back.svg'
import plusPurple from 'images/service/plus-purple.svg'
import crossGreen from 'images/service/cross-green.svg'
import closeIcon from 'images/close-btn.svg'
import { LocaleSwitcher } from 'components/shared/LocaleSwitcher'
import { AutoResizingTextarea } from 'components/shared/AutoResizingTextarea'
import meta from 'store/meta'
import { Menu } from 'components/shared/Menu'
import validationSchema from 'services/validation/schemas/sub_service.json'
import { useValidForm } from 'services/validation/validForm'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'
import { createServices } from 'api/master/service'
import { showAlert, getLocalizedCurrency } from 'services/helper'

export const Service = observer(() => {
  const navigate = useNavigate()
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState([])
  const { t } = useTranslation()
  const [specialization, setSpecialization] = useState('')
  const [allSpecializations, setAllSpecializations] = useState([])
  const [matchedSpecializations, setMatchedSpecializations] = useState([])
  const [selectedSpecializations, setSelectedSpecializations] = useState([])
  const [customSpecializations, setCustomSpecializations] = useState([])

  const handleSubmit = () => {
    if (!selectedSpecializations.length) return

    let errors = handleValidationErrors()
    if (isValidationErrorsEmpty(errors) && !hasEmptySubServices()) {
      meta.setLoader(true)
      const serviceIds = selectedSpecializations.map(service => service.id)
      const subServices = selectedSpecializations.flatMap(service => service.sub_services)
      createServices(serviceIds, subServices, customSpecializations)
        .then(data => {
          company.setServices(selectedSpecializations)
          meta.setLoader(false)
          if (!master.isKycApproved) {
            showAlert(t('master.services.create_success'))
            return navigate('/kyc')
          }

          if (master.kycs.at(-1)?.approved === false) {
            return navigate('/kyc_request')
          }

          showAlert(t('master.services.update_success'))
        })
    } else {
      showAlert(t('errors.correct_fields'))
    }
  }

  const handleValidationErrors = () => {
    let errors = selectedSpecializations.map((service) => {
      let ssr = service.sub_services.map((sub_service) => {
        let errors = validForm.validateFields({
          name: sub_service.name,
          price: sub_service.price,
          duration: sub_service.duration
        })
        return {
          id: sub_service.id,
          ...errors
        }
      })
      return {
        id: service.id,
        sub_services: ssr
      }
    })

    setValidationErrors(errors)
    return errors
  }

  const isValidationErrorsEmpty = (errors) => {
    return errors.every(service => {
      return service.sub_services.every(subService => {
        return subService.name.length === 0 && subService.price.length === 0 && subService.duration.length === 0
      })
    })
  }

  const hasEmptySubServices = () => {
    return selectedSpecializations.some(service => {
      return service.sub_services.length === 0
    })
  }

  const showSpecializations = (value) => {
    setSpecialization(value)
    let results = []
    if (value.length) {
      results = allSpecializations.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()) && !recordsById(selectedSpecializations)[s.id])
    }

    setMatchedSpecializations(results.slice(0, 5))
  }

  const chooseSpecialization = (spec) => {
    let specs = [spec, ...selectedSpecializations]
    setSelectedSpecializations(specs)
    setSpecialization('')
    setMatchedSpecializations([])
  }

  const recordsById = (records) => {
    return records.reduce((ac, record) => {
      return record.id ? { ...ac, [record.id]: record } : ac
    }, {})
  }

  const addCustomSpecialization = () => {
    const index = selectedSpecializations.findIndex(service => service.name === specialization)
    if (index !== -1) {
      const element = document.getElementById(`service-${index}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    } else {
      const newServiceId = uuidv4()
      setSelectedSpecializations(prevServices => [
        {
          id: newServiceId,
          name: specialization,
          sub_services: [
            {
              id: 1,
              name: '',
              duration: '',
              price: '',
              service_id: newServiceId
            },
          ]
        },
        ...prevServices
      ])

      setCustomSpecializations(prevServices => [...prevServices, { id: newServiceId, name: specialization }])
    }

    setSpecialization('')
    let resultsAll = allSpecializations.filter((s) => !recordsById(selectedSpecializations)[s.id])
    setMatchedSpecializations(resultsAll.slice(0, 5))
  }

  const dropSpecialization = (spec) => {
    let results = selectedSpecializations.filter((s) => s.id !== spec.id)
    setSelectedSpecializations(results)
    setCustomSpecializations(prevCustomSpecializations => {
      return prevCustomSpecializations.filter(sp => spec.id !== sp.id)
    })
    setSpecialization('')
    setMatchedSpecializations([])
  }

  const updateSubService = (service_id, sub_service_id, sub_service_field_name, value) => {
    const updatedServices = selectedSpecializations.map(service => {
      if (service.id === service_id) {
        const updatedSubServices = service.sub_services.map(subService => {
          if (subService.id === sub_service_id) {
            return {
              ...subService,
              [sub_service_field_name]: value
            }
          }
          return subService
        })
        return {
          ...service,
          sub_services: updatedSubServices
        }
      }
      return service
    })

    setSelectedSpecializations(updatedServices)
  }

  const addSubService = (service_id) => {
    setSelectedSpecializations(prevServices => {
      return prevServices.map(service => {
        if (service.id === service_id) {
          const newSubServiceId = service.sub_services.length ? Math.max(...service.sub_services.map(sub => sub.id)) + 1 : 1
          return {
            ...service,
            sub_services: [
              {
                id: newSubServiceId,
                name: '',
                duration: '',
                price: '',
                service_id: service.id
              },
              ...service.sub_services
            ]
          }
        }
        return service
      })
    })
  }

  const dropSubService = (service_id, sub_service_id) => {
    const updatedServices = selectedSpecializations.map(service => {
      if (service.id === service_id) {
        const updatedSubServices = service.sub_services.filter(subService => {
          if (subService.id !== sub_service_id) {
            return subService
          }
        })
        return {
          ...service,
          sub_services: updatedSubServices
        }
      }
      return service
    })

    setSelectedSpecializations(updatedServices)
  }

  const containsOnlyDigits = (event) => {
    if(isNaN(event.key) && event.key !== 'Backspace') {
      event.preventDefault()
    }
  }

  const viewValidationErrors = (service_id, sub_service_id, field) => {
    const service = validationErrors.find(service => service.id === service_id)
    if (service) {
      const subService = service.sub_services.find(subService => subService.id === sub_service_id)
      if (subService && subService[field].length !== 0) {
        return subService[field][0]
      }
    }
  }

  useEffect(() => {
    meta.setLoader(true)
    getAllSpecializations().then(data => {
      const services = data.services.map(service => {
        return {
          ...service,
          sub_services: service.base_sub_services
        }
      }).map(({ base_sub_services, ...rest }) => rest)
      setAllSpecializations(services)
      setSelectedSpecializations(company.services)
      meta.setLoader(false)
    })
  }, [company.services])

  return (
    <div className={ s.service }>
      <div className={ s.headCont }>
        <div className='container'>
          {
            (company.services.length && master.isKycApproved) ? <Menu /> : <div className={ s.head }>
              <div className={ s.headItem }>
                <img src={ loginBack } onClick={ () => navigate('/profile') }/>
              </div>
              <div className={ s.headItem }>
                <span>{ t('master.services.title') }</span>
              </div>
              <LocaleSwitcher />
            </div>
          }
        </div>
      </div>
      <div className='container'>
        <div className={ s.content }>
          <div className='textOnInput'>
            <label htmlFor='service'>{ t('master.services.title') }</label>
            <input
              id='service'
              type='text'
              onChange={ e => showSpecializations(e.target.value) }
              value={ specialization }
              name='name'
              placeholder={ t('master.profile.create.spec') }
            />
          </div>

          { !matchedSpecializations.length && specialization.length ?
            <div className={s.specializationNotFound}>
              <h3>{ t('master.services.not_found') }</h3>
              <p onClick={ () => addCustomSpecialization() }>{ t('master.services.add_custom') }</p>
            </div> :
            <div className={ s.specializations }>
              { matchedSpecializations.map(spec => (
                <span
                  className={ s.specialization }
                  key={ Math.random()}
                  onClick={ () => chooseSpecialization(spec) }
                >{ spec.name }</span>
              ))}
            </div>
          }

          <TransitionGroup className={ s.selectedSpecializations }>
            { selectedSpecializations.map((spec, index) => (
              <CSSTransition key={ spec.id } timeout={500} classNames='animated-item'>
                <div className={ s.selectedSpecialization } id={`service-${index}`}>
                  <div className={s.headCont}>
                    <div className={ s.serviceCont }>
                      <span>{ spec.name }</span>
                      <img src={ crossGreen } onClick={ () => dropSpecialization(spec) } />
                    </div>
                    <div className={ s.addServiceCont } onClick={ () => addSubService(spec.id) }>
                      <img src={ plusPurple } />
                      <span>{ t('master.services.add_sub_service') }</span>
                    </div>
                  </div>
                  { spec.sub_services.length === 0 && <p className={ s.emptyError }>{ t('master.services.provide') }</p> }
                  <TransitionGroup>
                    { spec.sub_services.map(sub_service => (
                      <CSSTransition key={ sub_service.id } timeout={500} classNames='animated-item'>
                        <div className={s.bodyCont}>
                          <div className={s.bodyContLeft}>
                            <div className={`${s.serviceName} textOnInput`}>
                              <label>{ t('master.services.name') }</label>
                              <AutoResizingTextarea
                                value={sub_service.name}
                                onChange={e => updateSubService(spec.id, sub_service.id, 'name', e.target.value)}
                                className={ viewValidationErrors(spec.id, sub_service.id, 'name') ? s.errorInput : '' }
                              />
                            </div>
                            <p className={ s.ssError }>{viewValidationErrors(spec.id, sub_service.id, 'name')}</p>
                            <div className={`${s.servicePrice} textOnInput`}>
                              <label>{ t('master.services.price') }</label>
                              <input
                                type='number'
                                onChange={ e => updateSubService(spec.id, sub_service.id, 'price', e.target.value) }
                                value={ sub_service.price }
                                name='price'
                                placeholder={ t(getLocalizedCurrency()) } 
                                onKeyDown={ e => containsOnlyDigits(e) }
                                className={ viewValidationErrors(spec.id, sub_service.id, 'price') ? s.errorInput : '' }
                              />
                            </div>
                            <p className={ s.ssError }>{viewValidationErrors(spec.id, sub_service.id, 'price')}</p>
                            <div className={`${s.serviceDuration} textOnInput`}>
                              <label>{ t('master.services.time') }</label>
                              <input
                                type='number'
                                onChange={ e => updateSubService(spec.id, sub_service.id, 'duration', e.target.value) }
                                value={ sub_service.duration }
                                name='name'
                                placeholder={ t('master.services.min') }
                                onKeyDown={ e => containsOnlyDigits(e) }
                                className={ viewValidationErrors(spec.id, sub_service.id, 'duration') ? s.errorInput : '' }
                              />
                            </div>
                            <p className={ s.ssError }>{viewValidationErrors(spec.id, sub_service.id, 'duration')}</p>
                          </div>
                          <img
                            src={ closeIcon }
                            onClick={ e => dropSubService(spec.id, sub_service.id) }
                          />
                        </div>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        <button
          className={ `${s.nextButton} pressedButton ${selectedSpecializations.length ? '' : s.disabled}` }
          onClick={ e => handleSubmit(e) }>
          { company.services.length ? t('master.services.update') : t('master.services.create') }
        </button>
      </div>
    </div>
  )
})
