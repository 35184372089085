import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getMaster = () => {
  const query = () => {
    return fetch(`${API_URL}/masters`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const updateMaster = (lang = '') => {
  const query = () => {
    return fetch(`${API_URL}/masters`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        id: master.id,
        lang: lang
      })
    })
  }

  return backOffCall(query)
}

export {
  getMaster,
  updateMaster
}
