import { makeAutoObservable } from 'mobx'
import master from 'store/master'
import { SOCKET_URL } from 'config/constants'

class OrderMonitorSocket {
  ws = null
  needReset = false

  constructor() {
    makeAutoObservable(this)
  }

  init() {
    if (this.isActive()) return

    this.needReset = false
    this.ws = new WebSocket(SOCKET_URL, ['actioncable-v1-json', master.token])

    this.ws.onclose = () => setTimeout(() => this.init(), 2000)

    this.ws.onopen = () => {
      this.subscribe()
    }

    this.ws.onmessage = (event) => {
      let data = JSON.parse(event.data)
      if (data.type === 'ping') {
        return
      }
      if (data.message && data.message.hasOwnProperty('order') && data.message.hasOwnProperty('action')) {
        switch (data.message.action) {
        case 'add':
          master.addOrder(data.message.order)
          break
        case 'remove':
          master.removeOrder(data.message.order)
          break
        default:
          break
        }
      }
    }
  }

  subscribe() {
    this.sendMessage('subscribe')
  }

  unsubscribe() {
    if (this.ws) {
      this.sendMessage('unsubscribe')
      this.ws = null
      this.needReset = false
    }
  }

  sendMessage(command) {
    master.serviceIds.forEach(id => {
      let message = {
        command: command,
        identifier: JSON.stringify({
          channel: 'OrderMonitorChannel',
          service_id: id,
          city_id: master.city.id
        })
      }
      this.ws.send(JSON.stringify(message))
    })
  }

  check(visibilityState) {
    if (!this.ws) return

    if (visibilityState === 'visible' && !this.isActive()) {
      this.needReset = true
    }
  }

  isActive() {
    return [0, 1].includes(this.ws?.readyState)
  }
}

export default new OrderMonitorSocket()
