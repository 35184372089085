import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import mainImage from 'images/referral/referral-main.svg'
import listImage from 'images/copy.svg'
import s from 'styles/referral_program/ReferralProgram.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import config from 'store/config'
import { showAlert } from 'services/helper'

export const Index = observer(({ referralProgramData }) => {
  const { t } = useTranslation()
  const referralCode = referralProgramData?.referral_program?.referral_code
  const referrals = referralProgramData?.referrals
  const navigate = useNavigate()
  const copyCode = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(referralCode).then(() => {
        showAlert(t('master.referral_program.index.copied'))
      }).catch(() => {
        showAlert(t('errors.try_again'))
      })
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = referralCode
      document.body.appendChild(textArea)
      textArea.select()
      try {
        document.execCommand('copy')
        showAlert(t('master.referral_program.index.copied'))
      } catch {
        showAlert(t('errors.try_again'))
      }
      document.body.removeChild(textArea)
    }
  }

  const showReferrals = () => {
    navigate('/referrals')
  }

  return (
    <div className={ s.referralProgram }>
      <img className={ s.mainImage } src={ mainImage } />
      <div className={ s.leftCont }>
        <h1>{ t('master.referral_program.index.title') }</h1>
        <div className={ s.counterCont }>
          <span>{ `${referrals?.length}/${config.constants.MAX_REFERRALS_AMOUNT}` }</span>
          <img src={ listImage } onClick={ () => showReferrals() } />
        </div>
        <p>{ t('master.referral_program.index.quote', { amount: config.constants.REFERRAL_BONUS_AMOUNT }) }</p>
      </div>
      <div className={ s.codeCont }>
        <span>{ referralCode }</span>
      </div>

      <button className={ `${s.copyButton} pressedButton` } onClick={ () => copyCode() }>
        { t('master.referral_program.index.copy') }
      </button>
    </div>
  )
})
