import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const createCompany = (
  {
    companyType = 0,
    companyName = '',
    address = '',
    lat = null,
    lng = null,
    field = '',
    cityId
  }) => {
  const query = () => {
    return fetch(`${API_URL}/companies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        company: {
          company_type: companyType,
          name: companyName,
          field: field,
          city_id: cityId,
          address: address,
          lat: lat,
          lng: lng
        }
      })
    })
  }

  return backOffCall(query)
}

const updateCompany = (
  {
    active = false
  }) => {
  const query = () => {
    return fetch(`${API_URL}/companies`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },  
      body: JSON.stringify({
        company: {
          active: active
        },
        schedules: []
      })
    })
  }

  return backOffCall(query)
}

export {
  createCompany,
  updateCompany
}
