import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import validationSchema from 'services/validation/schemas/profile.json'
import { useValidForm } from 'services/validation/validForm'
import { useState } from 'react'
import { createProfile } from 'api/master/profile.js'
import s from 'styles/Profile.module.css'
import { useTranslation } from 'react-i18next'
import master from 'store/master'
import meta from 'store/meta'
import company from 'store/company'
import { Schedule } from 'components/profile/Schedule'
import { convertKeysToCamelCase } from 'services/helper'
import { useNavigate } from 'react-router-dom'
import addPhoto from 'images/add-photo.svg'
import config from 'store/config'
import { getImage, getPresignedUrl, uploadToBucket } from 'api/image'
import { Autocomplete } from 'components/shared/Autocomplete'
import { Spinner } from 'components/Spinner'
import { showAlert } from 'services/helper'

export const Individual = observer(({ cities }) => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [middleName, setMiddleName] = useState(master.profile?.middle_name || '')
  const [firstName, setFirstName] = useState(master.profile?.first_name || '')
  const [lastName, setLastName] = useState(master.profile?.last_name || '')
  const [cityId, setCityId] = useState(company.city?.id || '')
  const [address, setAddress] = useState(company?.address || '')
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [validateSchedule, setValidateSchedule] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [identifier, setIdentifier] = useState('')
  const [image, setImage] = useState(null)
  const [isImageError, setIsImageError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    setValidateSchedule(!validateSchedule)
  }

  async function preparePreSignedUrl(file) {
    setIsLoading(true)
    let get_presigned_url = await getPresignedUrl()
    await uploadToBucket(get_presigned_url.url, file)
    setIdentifier(get_presigned_url.identifier)
    setIsLoading(false)
  }

  const handleImageChange = (event) => {
    const img = event.target.files[0]

    if (!img) return

    if (img.size / (1024 * 1024) > config.constants.MAX_IMG_SIZE_MB) {
      setIsImageError(true)
      setImage(null)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(img)
    setIsImageError(false)

    preparePreSignedUrl(img)
  }

  const getSchedule = (schedules) => {
    setValidationErrors(validForm.validateFields({ firstName, middleName, lastName, address }))
    if (validForm.isValid() && schedules.valid && addressIsValid()) {
      meta.setLoader(true)
      createProfile({
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        companyType: 0,
        companyName: `${firstName} ${lastName}`,
        identifier: identifier,
        cityId: cityId,
        address: address,
        lat: lat,
        lng: lng,
        schedules: schedules.backend_data
      }).then(data => {
        meta.setLoader(false)
        if (data?.errors?.profile?.hasOwnProperty('validation')) {
          setValidationErrors(convertKeysToCamelCase(data.errors.profile.validation))

          return
        }

        if (data?.errors?.company?.hasOwnProperty('validation')) {
          let errors = data.errors.company.validation
          if (errors.hasOwnProperty('lat') || errors.hasOwnProperty('lng')) {
            delete errors.lat
            delete errors.lng
            errors.address = [t('autocomplete.error')]
          }
          setValidationErrors(errors)

          return
        }

        if (data?.errors) {
          showAlert(data?.errors)
          return
        }

        master.setProfile({
          first_name: firstName,
          last_name: lastName,
          middle_name: middleName,
        })

        company.setCompanyProfileData({
          name: `${firstName} ${lastName}`,
          company_type: 0,
          address: address,
          schedule: schedules.data,
          city: cities.find(city => city.id == cityId)
        })

        if (company.services.length && master.isKycApproved) {
          showAlert(t('master.profile.update.success'))
        } else {
          showAlert(t('master.profile.create.success'))
          return navigate('/service')
        }
      })
    } else {
      showAlert(t('errors.correct_fields'))
    }
  }

  const getAddressData = (data) => {
    setAddress(data.address)
    setLat(data.lat)
    setLng(data.lng)
  }

  const addressIsValid = () => {
    if (typeof lat === 'string' && typeof lng === 'string') {
      setValidationErrors(errors => {
        errors.address = [t('autocomplete.error')]
        return errors
      })

      return false
    }

    setValidationErrors(errors => {
      errors.address = []
      return errors
    })

    return true
  }

  useEffect(() => {
    setMiddleName(master.profile?.middle_name || '')
    setFirstName(master.profile?.first_name || '')
    setLastName(master.profile?.last_name || '')
    setAddress(company?.address || '')
    setCityId(company.city?.id || '')
  }, [master.profile, company.address, company.city])

  useEffect(() => {
    if (cities.length && !cityId) {
      setCityId(cities[0].id)
    }
  }, [cities])

  useEffect(() => {
    getImage('company')
      .then(data => {
        setImage(data.image)
      })
  }, [])

  return (
    <form>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      <div className={s.photoCont}>
        { 
          isLoading ? <span className={s.defaultImage}><Spinner /></span> :
            (image ? <img src={image} className={s.image} alt='logo' /> : <span className={s.defaultImage}>🐸</span>)
        }
        <label htmlFor="fileInput" className={s.imageLabel}>
          <img src={addPhoto} alt='add photo'/>
          <span>{ t('master.profile.create.photo') }</span>
          <p className={ isImageError ? s.error : '' }>
            { t('master.profile.create.max_size', { amount: config.constants.MAX_IMG_SIZE_MB }) }
          </p>
        </label>
      </div>
      <div className='textOnInput'>
        <label htmlFor='name'>{ t('master.profile.create.name') }</label>
        <input
          id='name'
          type='text'
          onChange={ e => setFirstName(e.target.value) }
          value={ firstName }
          name='name'
        />
      </div>
      <div className={ s.error }>{ validationErrors.firstName }</div>

      <div className='textOnInput'>
        <label htmlFor='surname'>{ t('master.profile.create.surname') }</label>
        <input
          id='surname'
          type='text'
          onChange={ e => setLastName(e.target.value) }
          value={ lastName }
          name='surname'
        />
      </div>
      <div className={ s.error }>{ validationErrors.lastName }</div>

      <div className='textOnInput'>
        <label htmlFor='patronymic'>{ t('master.profile.create.patronymic') }</label>
        <input
          id='patronymic'
          type='text'
          onChange={ e => setMiddleName(e.target.value) }
          value={ middleName }
          name='middleName'
        />
      </div>
      <div className={ s.error }>{ validationErrors.middleName }</div>

      <div className='textOnInput'>
        <label htmlFor='city'>{ t('master.profile.create.city') }</label>
        <select className='city' value={ cityId ? cityId : cities[0]?.id } onChange={ e => setCityId(e.target.value) }>
          { cities.map(city => (
            <option value={ city.id } key={ city.id } >{ city.name }</option>
          ))}
        </select>
      </div>

      <Autocomplete
        isLoaded={ meta.isMapLoaded }
        defaultValue = { address }
        sendAddressData ={ getAddressData }
      />
      <div className={ s.error }>{ validationErrors.address }</div>

      <p className={s.addressQuote}>{ t('master.profile.address_quote') }</p>
      <Schedule sendSchedule={getSchedule} validate={validateSchedule}/>
      <button className={`${s.nextButton} pressedButton ${isLoading ? s.disabledButton : ''}`} disabled={ isLoading } onClick={ e => handleSubmit(e) }>
        { master.profile ? t('master.profile.update.submit') : t('master.profile.create.submit') }
      </button>
    </form>
  )
})
