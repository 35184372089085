import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import master from 'store/master'
import s from 'styles/OrdersMonitor.module.css'
import { Menu } from 'components/shared/Menu'
import preview from 'images/monitor/preview.svg'
import terminovo from 'images/terminovo.svg'
import meta from 'store/meta'
import { getOrders } from 'api/master/order'
import { UTCDateStringToLocalHHMM, UTCDateStringToLocalMMDDYYYY } from 'services/dateFormatter'
import { useTranslation } from 'react-i18next'
import { cancelOrder, acceptOrder } from 'api/master/order'
import { getLocalizedCurrency } from 'services/helper'

export const OrdersMonitor = observer(() => {
  const [orders, setOrders] = useState([])
  const { t } = useTranslation()
  const [seconds, setSeconds] = useState(60)
  const [isRunning, setIsRunning] = useState(false)

  const acceptOrderApi = (id) => {
    meta.setLoader(true)
    acceptOrder(id)
      .then(data => {
        if (data.errors) {
          alert(data.errors.server)
        } else {
          let updatedOrders = orders.map(order =>
            order.id === id ? { ...order, state: 'accepted' } : order
          )
          setOrders(updatedOrders)
          startTimer()
        }
        meta.setLoader(false)
      })
  }

  const cancelOrderApi = (id) => {
    meta.setLoader(true)
    cancelOrder(id)
      .then(data => {
        if (data.errors) {
          alert(data.errors.server)
        } else {
          setOrders(prevItems => prevItems.filter(order => order.id !== id))
        }
        meta.setLoader(false)
      })
  }

  useEffect(() => {
    let interval = null
    if (isRunning) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => (prevSeconds > 0 ? prevSeconds - 1 : 0))
      }, 1000)
    } else if (!isRunning && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isRunning, seconds])

  const startTimer = () => {
    setIsRunning(true)
  }

  useEffect(() => {
    if (!master.currentOrder) return

    if (master.currentOrder?.state === 'initial') {
      setOrders((prevItems) => {
        const itemIndex = prevItems.findIndex(item => item.id === master.currentOrder?.id)
        if (itemIndex === -1) {
          return [...prevItems, master.currentOrder]
        } else {
          return prevItems.map(item =>
            item.id === master.currentOrder.id ? master.currentOrder : item
          )
        }
      })

      return
    }

    if (['paid', 'canceled'].includes(master.currentOrder?.state)) {
      setOrders((prevItems) => prevItems.filter(item => item.id !== master.currentOrder?.id))
    }
  }, [master.currentOrder])

  useEffect(() => {
    meta.setLoader(true)
    getOrders(['accepted', 'initial'])
      .then(data => {
        if (data.orders) {
          setOrders(data.orders)
        }
        meta.setLoader(false)
      })
  }, [])

  return (
    <div className={`${s.monitor} container`}>
      <Menu />
      <div>
        { !orders.length ?
          <div className={s.noOrders}>
            <img src={ preview } className={s.previewImg} />
            <img src={ terminovo } className={s.terminovoImg}/>
            <h1>{ t('master.monitor.preview_title') }</h1>
            <p>{ t('master.monitor.preview_quote') }</p>
          </div> :
          orders.map(order => (
            <div className={ s.order } key={ order.id }>
              <div className={ s.orderHead }>
                <div className={ s.topHolder }>
                  <span className={ s.priceTitle }>{ UTCDateStringToLocalMMDDYYYY(order.start_at) }</span>
                  <span className={ s.serviceName }>{ order.service.name }</span>
                </div>
              </div>
              <div className={ s.orderSubHead }>
                <div className={ s.div1 }>
                  <span className={ s.span1 }>{ t('master.orders.commission') }</span>
                  <span className={ s.span2 }>{ order.fee } { t(getLocalizedCurrency()) }</span>
                  <span className={ s.span3 }>≈ { order.start_price } { t(getLocalizedCurrency()) } { t('master.orders.price') }</span>
                </div>
                <div className={ s.div2 }>
                  <span className={ s.span1 }>{ t('master.monitor.time') }</span>
                  <span className={ s.span2 }>{ `${UTCDateStringToLocalHHMM(order.start_at)}-${UTCDateStringToLocalHHMM(order.end_at)}` }</span>
                </div>
              </div>
              <div className={ s.orderBody }>
                <span>{ t('master.monitor.address') }</span>
                <p className={ s.bold }>{ order.address }</p>
                <span>{ t('master.monitor.contact_info') }</span>
                <p className={ s.bold }>{ `+${order?.client?.phone_number}, ${order?.client?.profile?.first_name}` }</p>
                <span>{ t('master.monitor.description') }</span>
                <p>{ order.service_request?.prompt }</p>
                {
                  order.state === 'accepted' ?
                    <button className={ s.waitButton }>
                      { `${t('master.monitor.wait')} (${ seconds } ${t('master.monitor.sec')})` }
                    </button> : <>
                      <button className={ `${s.acceptButton} pressedButton` } onClick={ () => acceptOrderApi(order.id) }>
                        { t('master.monitor.accept') }
                      </button>
                      <button className={ s.declineButton } onClick={ () => cancelOrderApi(order.id) }>
                        { t('master.monitor.decline') }
                      </button>
                    </>
                }
              </div>
            </div>
          ))}
        <br/>
      </div>

    </div>
  )
})
