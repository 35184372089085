import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const createKyc = (entity, identifier) => {
  const query = () => {
    return fetch(`${API_URL}/master/kycs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        identifier: identifier,
        entity: entity
      })
    })
  }

  return backOffCall(query)
}

const getKycs = () => {
  const query = () => {
    return fetch(`${API_URL}/master/kycs`, {
      method: 'GET',
      headers: {
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

export {
  createKyc,
  getKycs
}
