import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/modals/ServerDownModal.module.css'
import meta from 'store/meta'
import { Modal } from 'components/shared/Modal'
import imageStarGreen from 'images/star-green.svg'
import imageStarBlack from 'images/star-black.svg'
import { useTranslation } from 'react-i18next'

export const ServerDownModal = observer(() => {
  const { t } = useTranslation()

  const setActive = (state) => {
    meta.isOnline ? meta.setServerDownModal(state) : meta.setServerDownModal(true)
  }

  return (
    <Modal active={ meta.isServerDownModal } setActive={ (state) => setActive(state) }>
      <div className={ s.orderConfirmedModal }>
        <img src={imageStarGreen} className={s.starGreen} />
        <img src={imageStarBlack} className={s.starBlack} />
        <h2>No connection</h2>
        <p>Try again later</p>
      </div>
    </Modal>
  )
})
