import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getPaymentRequest = (id = '') => {
  const query = () => {
    return fetch(`${API_URL}/payment_requests/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const createPaymentRequest = (amount = 0, payment_type = 'debit') => {
  const query = () => {
    return fetch(`${API_URL}/payment_requests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        amount: amount,
        payment_type: payment_type
      })
    })
  }

  return backOffCall(query)
}

export {
  createPaymentRequest,
  getPaymentRequest
}
