import { React, useEffect } from 'react'
import terminovo from 'images/terminovo.svg'
import starPurple from 'images/star-purple-light.svg'
import starBlack from 'images/star-black.svg'
import smileBlue from 'images/profile/blue-smile.svg'
import smileYellow from 'images/profile/yellow-smile.svg'
import smileGreen from 'images/profile/green-smile.svg'
import s from 'styles/CompanyRequest.module.css'
import { useTranslation } from 'react-i18next'
import { ConfirmButton } from 'components/shared/ConfirmButton'
import loginBack from 'images/login-back.svg'
import master from 'store/master'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

export const CompanyRequest = observer(({ text = 'master.company.request' }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const logout = () => {
    master.logout()
    navigate('/login')
  }

  useEffect(() => {
    if (master.kycs.some(kyc => kyc.approved === true)) {
      return navigate('/')
    }
  }, [master.kycs])

  return (
    <div className={`${s.companyRequest} container`}>
      <div className={ s.headItem }>
        <ConfirmButton
          onConfirm={ logout }
          confirmText = { t('master.profile.logout') }
          className='border-0 bg-transparent'
        >
          <img src={ loginBack } />
        </ConfirmButton>
      </div>
      <div className={s.starCont}>
        <img src={starPurple}/>
        <img src={starBlack}/>
      </div>
      <img src={terminovo} className={s.terminovo} />
      <p>{ t(text) }</p>
      <img src={starBlack} className={s.starBlack} />
      <img src={smileBlue} className={s.smileBlue} />
      <img src={smileYellow} className={s.smileYellow} />
      <img src={smileGreen} className={s.smileGreen} />
    </div>
  )
})
