import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getProfile = () => {
  const query = () => {
    return fetch(`${API_URL}/master/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const createProfile = (
  {
    firstName = '',
    lastName = '',
    middleName = '',
    companyType = 0,
    companyName = '',
    field = '',
    identifier,
    cityId,
    address = '',
    lat = null,
    lng = null,
    entity = 'company',
    schedules
  }) => {
  let profile = {
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName
  }
  let company = {
    company_type: companyType,
    name: companyName,
    field: field,
    city_id: cityId,
    address: address,
  }
  if (lat && lng) {
    company.lat = lat
    company.lng = lng
  }

  let method = master.profile ? 'PATCH' : 'POST'
  const query = () => {
    return fetch(`${API_URL}/master/profile`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        profile: profile,
        company: company,
        schedules: schedules,
        identifier: identifier,
        entity: entity
      })
    })
  }

  return backOffCall(query)
}

export {
  getProfile,
  createProfile
}
