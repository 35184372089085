import { makeAutoObservable } from 'mobx'
import { getConstants } from 'api/master/constants'

class Config {
  constants = {
    FALL_BACK_LNG: 'ua',
    LOCALES: ['en', 'ua', 'ru'],
    BACKOFF_RECONNECTION_COUNT: 4,
    BACKOFF_STATUS_CODES: [408, 504]
  }
  isLoaded = false

  constructor() {
    makeAutoObservable(this)
  }

  async loadConfig() {
    getConstants()
      .then(data => {
        if (data.constants) {
          this.constants = data.constants
        }
        this.isLoaded = true
      })
  }
}

export default new Config()
