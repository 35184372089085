import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/tasks/Tasks.module.css'
import { useTranslation } from 'react-i18next'
import meta from 'store/meta'
import loginBack from 'images/login-back.svg'
import { useNavigate } from 'react-router-dom'
import master from 'store/master'
import company from 'store/company'
import nextImage from 'images/next-arrow.svg'
import handImage from 'images/bonus/hand.png'
import { getMasterTasks, updateMasterTasks } from 'api/master/master_task'
import { Show as TaskShow } from 'components/tasks/Show'
import { showAlert, getLocalizedCurrency } from 'services/helper'
import { Modal } from 'components/shared/Modal'

export const Tasks = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tasks, setTasks] = useState([])
  const [currentTask, setCurrentTask] = useState(null)
  const [isModalActive, setIsModalActive] = useState(false)
  const [currentTaskPrice, setCurrentTaskPrice] = useState(null)

  const updateMasterTaskApi = (info = null) => {
    if (!currentTask) return
    let showModalAfter = 5000
    let data = null
    switch (currentTask?.task_type) {
    case 'click':
      data = null
      break
    case 'options':
      data = info
      showModalAfter = 0
      break
    default:
      return
    }

    meta.setLoader(true)
    updateMasterTasks(currentTask.id, data)
      .then(data => {
        if (data.hasOwnProperty('errors')) {
          showAlert(t('master.kyc.size_error'))
        } else {
          afterTaskUpdation(showModalAfter)
        }
        meta.setLoader(false)
      })
  }

  const afterTaskUpdation = (showModalAfter) => {
    handleTaskAction()
    setTimeout(() => {
      const updatedTasks = tasks.map(task =>
        task.id === currentTask.id ? { ...task, finished: true } : task
      )
      master.setTasks(updatedTasks)
      setTasks(updatedTasks)
      setCurrentTaskPrice(currentTask.bonus_amount)
      company.incrementAvailableBalance(currentTask.bonus_amount)
      setCurrentTask(null)
      setIsModalActive(true)
    }, showModalAfter)
  }

  const handleTaskAction = () => {
    if (currentTask.task_type === 'click') {
      window.cordova ?
        cordova.InAppBrowser.open(currentTask.data.url, '_system') :
        window.open(currentTask.data.url, '_blank')
    }
  }

  useEffect(() => {
    if (currentTask?.task_type !== 'click') return

    updateMasterTaskApi()
  }, [currentTask])

  useEffect(() => {
    if (master.tasks?.length) {
      setTasks(master.tasks)

      return
    }

    meta.setLoader(true)
    getMasterTasks()
      .then(data => {
        if (data.hasOwnProperty('master_tasks')) {
          master.setTasks(data.master_tasks)
          setTasks(data.master_tasks)
        }
        meta.setLoader(false)
      })
  }, [])

  return (
    <div className={ s.tasks }>
      { currentTask?.task_type === 'options' ?
        <TaskShow
          task={ currentTask }
          close={ () => setCurrentTask(null) }
          updateMasterTasks={ (option) => updateMasterTaskApi(option)}
        /> : <div className='container'>
          <div className={s.header}>
            <div>
              <img src={ loginBack } onClick={ () => navigate(-1) }/>
            </div>
            <div className={ s.title }>{ t('master.menu.tasks') }</div>
            <div className={ s.rightEmptyBlock }></div>
          </div>
          <div className={ s.tasksCont }>
            { tasks.map((task, i) => (
              <div className={ s.taskItem } key={i}>
                <div className={ s.firstDiv }>
                  <h1>🎉 { t('tasks.bonuses', { bonus_amount: task.bonus_amount }) }</h1>
                  <p>{ t(task.name) }</p>
                </div>
                <div className={ s.secondDiv }>
                  { task.finished ? <span>✅</span> : <img src={nextImage} onClick={ () => setCurrentTask(task) }/> }
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      <Modal active={ isModalActive } setActive={ (state) => setIsModalActive(state) }>
        <div className={ s.modalContent }>
          <img src={ handImage } />
          <h2>🎉 +{ currentTaskPrice } { t(getLocalizedCurrency()) }</h2>
          <p>{ t('tasks.finished_title') }</p>
          <button className='pressedButton' onClick={ () => setIsModalActive(false) }>
            { t('tasks.finished_button') }
          </button>
        </div>
      </Modal>
    </div>
  )
})
