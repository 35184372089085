import { backOffCall } from 'api/api.js'
import { API_URL } from 'config/constants'

const isFeatureEnabled = (feature) => {
  const query = () => {
    return fetch(`${API_URL}/flipper_features?feature=${feature}`, {
      headers: {
        'Accept-Language': 'ua',
      },
    })
  }

  return backOffCall(query)
}

export {
  isFeatureEnabled
}
