import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getReferralProgramData = () => {
  const query = () => {
    return fetch(`${API_URL}/master/referral_programs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const createReferralProgram = (referredBy = null) => {
  const query = () => {
    return fetch(`${API_URL}/referral_programs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token
      },
      body: JSON.stringify({
        referred_by: referredBy,
      })
    })
  }

  return backOffCall(query)
}

export {
  getReferralProgramData,
  createReferralProgram
}
