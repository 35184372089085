import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import mainImage from 'images/bonus/hands.svg'
import s from 'styles/tasks/Index.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const Index = observer(({ tasks }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const finishedTasksCount = tasks.filter(task => task.finished).length

  return (
    <div className={ s.taskIndex }>
      <div>
        <h1>{ t('master.menu.tasks') }</h1>
        <div className={ s.counterCont }>
          <span>{ `${finishedTasksCount}/${tasks?.length}` }</span>
        </div>
      </div>
      <img className={ s.mainImage } src={ mainImage } />
      <p className={ s.quote1 } >{ t('tasks.title') }</p>
      <p className={ s.quote2 }>{ t('tasks.description') }</p>
      <button className={ `${s.tasksButton} pressedButton` } onClick={ () => navigate('/tasks') }>
        { t('tasks.view') }
      </button>
    </div>
  )
})
