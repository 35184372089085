import { makeObservable, observable, action } from 'mobx'
class Company {
  name = null
  company_type = null
  rating = null
  address = null
  field = null
  city = { timezone: 'Europe/Kiev' }
  balance = null
  active = false
  schedule = []
  services = []

  constructor() {
    makeObservable(this, {
      name: observable,
      company_type: observable,
      rating: observable,
      address: observable,
      field: observable,
      city: observable,
      schedule: observable,
      balance: observable,
      services: observable,
      active: observable,
      setCity: action,
      setCompanyData: action,
      setServices: action,
      updateAvailableBalance: action,
      incrementAvailableBalance: action,
      clearState: action
    })
  }

  setCompanyData(data) {
    if (data === null) return

    this.name = data.name
    this.company_type = data.company_type
    this.rating = data.rating
    this.address = data.address
    this.field = data.field
    this.city = data.city
    this.active = data.active
    this.schedule = data.schedules
    this.balance = data.balance
  }

  setCompanyProfileData(data) {
    this.name = data.name
    this.company_type = data.company_type
    this.address = data.address
    this.schedule = data.schedule
    this.city = data.city
  }

  setCity(city) {
    this.city = city
  }

  setServices(services) {
    this.services = services
  }

  clearState() {
    this.name = null
    this.company_type = null
    this.logo = null
    this.rating = null
    this.address = null
    this.field = null
    this.city = { timezone: 'Europe/Kiev' }
    this.balance = null
    this.schedule = []
    this.services = []
  }

  updateAvailableBalance(amount) {
    this.balance.available_amount = amount
  }

  incrementAvailableBalance(amount) {
    this.balance.available_amount += amount
  }
}

export default new Company()
