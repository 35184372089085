import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'

const getOrders = (states = []) => {
  const query = () => {
    let params = new URLSearchParams()
    states.forEach(state => params.append('state[]', state))

    return fetch(`${API_URL}/orders?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
    })
  }

  return backOffCall(query)
}

const getMonitorOrders = (state = '', serviceIds = []) => {
  const query = () => {
    let params = new URLSearchParams()
    serviceIds.forEach(id => params.append('service_id[]', id))
    params.append('state', state)

    return fetch(`${API_URL}/orders?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token,

      },
    })
  }

  return backOffCall(query)
}

const getCurrentOrder = () => {
  const query = () => {
    return fetch(`${API_URL}/orders/current`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token
      },
    })
  }
  return backOffCall(query)
}

const acceptOrder = (id = '') => {
  const query = () => {
    return fetch(`${API_URL}/orders/accept`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token
      },
      body: JSON.stringify({
        id: id,
      })
    })
  }

  return backOffCall(query)
}

const cancelOrder = (id = '') => {
  const query = () => {
    return fetch(`${API_URL}/orders/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token
      },
      body: JSON.stringify({
        id: id,
      })
    })
  }

  return backOffCall(query)
}

const completeOrder = (id = '') => {
  const query = () => {
    return fetch(`${API_URL}/orders/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'ua',
        'Authorization': master.token
      },
      body: JSON.stringify({
        id: id,
      })
    })
  }

  return backOffCall(query)
}

export {
  getOrders,
  getMonitorOrders,
  getCurrentOrder,
  acceptOrder,
  cancelOrder,
  completeOrder
}
