import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import s from '../styles/Payment.module.css'
import { Menu } from 'components/shared/Menu'
import { useTranslation } from 'react-i18next'
import company from 'store/company'
import master from 'store/master'
import meta from 'store/meta'
import { getTransactions } from 'api/master/transaction'
import { getProfile } from 'api/master/profile'
import config from 'store/config'
import { createPaymentRequest, getPaymentRequest } from 'api/master/payment'
import validationSchema from 'services/validation/schemas/payment.json'
import { useValidForm } from 'services/validation/validForm'
import { showAlert, getLocalizedCurrency } from 'services/helper'
import { UTCDateStringToLocalYYYYMMDD } from 'services/dateFormatter'
import { EmptyEntities } from 'components/shared/EmptyEntities'

export const Payment = observer(() => {
  const { t } = useTranslation()
  const [transactions, setTransactions] = useState([])
  const [amount, setAmount] = useState('')
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [paymentRequestId, setPaymentRequestId] = useState(null)

  const replenishBalance = () => {
    setValidationErrors(validForm.validateFields({ amount }))
    if (validForm.isValid()) {
      meta.setLoader(true)
      setValidationErrors(validForm.initialState())
      createPaymentRequest(amount)
        .then(data => {
          if (data.hasOwnProperty('payment_request_id')) {
            handleWebview(data.payment_link, data.payment_request_id)
          } else {
            meta.setLoader(false)
            showAlert(t('master.payment.error'))
          }
        })
    }
  }

  const handleWebview = (link, id) => {
    if (window.cordova) {
      meta.setLoader(false)
      setAmount('')
      setPaymentRequestId(id)
      return window.cordova.InAppBrowser.open(link, '_system', 'location=no')
    }

    window.open(link, '_self')
  }

  const checkPaymentRequest = id => {
    meta.setLoader(true)
    setTimeout(() => {
      getPaymentRequest(id)
        .then(data => {
          if (data.hasOwnProperty('id')) {
            handlePaymentRequest(data)
          } else {
            showAlert(t('master.payment.error'))
          }
        })
    }, config.constants.PAYMENT_CALLBACK_CHECK_INTERVAL)
  }

  const handlePaymentRequest = (data) => {
    switch (data.state) {
    case 'success':
      handleBalance()
      meta.setLoader(false)
      master.setNeedPaymentCheck(false)
      setPaymentRequestId(null)
      showAlert(t('master.payment.success'))
      break
    case 'failure':
      meta.setLoader(false)
      showAlert(t('master.payment.error'))
      master.setNeedPaymentCheck(false)
      setPaymentRequestId(null)
      break
    default:
      checkPaymentRequest(data.id)
      break
    }
  }

  const viewDate = (dateString) => {
    return UTCDateStringToLocalYYYYMMDD(dateString)
  }

  const containsOnlyDigits = (event) => {
    if(isNaN(event.key) && event.key !== 'Backspace') {
      event.preventDefault()
    }
  }

  const beatifySourceString = (string) => {
    if (string.length === 36) {
      return `${t('master.orders.order')} #${string.slice(-12)}`
    }
  }

  const handleBalance = () => {
    getProfile()
      .then(data => {
        if (data?.company?.balance) {
          if (data.company.balance.available_amount > company?.balance?.available_amount) {
            showAlert(t('master.payment.success'))
          }
          company.updateAvailableBalance(data.company.balance.available_amount)
          setPaymentRequestId(null)
          master.setNeedPaymentCheck(false)
        }
      })
    getTransactions().then(data => {
      meta.setLoader(false)
      setTransactions(data.transactions)
    })
  }

  useEffect(() => {
    getTransactions().then(data => {
      meta.setLoader(false)
      setTransactions(data.transactions)
    })

    if (window.cordova) {
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          setTimeout(() => {
            if (!master.needPaymentCheck) {
              handleBalance()
            }
          }, 200)
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)
    }

    return () => {
      if (window.cordova) {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [])

  useEffect(() => {
    if (master.needPaymentCheck && paymentRequestId) {
      checkPaymentRequest(paymentRequestId)
    }
  }, [master.needPaymentCheck])

  return (
    <div className={`${s.payment} container`}>
      <Menu />
      <p className={ s.balanceQuote }>{ t('master.payment.balance') }</p>
      <span className={ s.balanceString } >{ company?.balance?.available_amount } { t(getLocalizedCurrency()) }</span>
      <p className={ s.balanceReplenish }>{ t('master.payment.replenish') }</p>
      <input
        type='number'
        onChange={ e => setAmount(e.target.value) }
        value={ amount }
        onKeyDown={ e => containsOnlyDigits(e) }
        placeholder={ t(getLocalizedCurrency()) }
      />
      <button className={ `${s.replenishButton} pressedButton` } onClick={ () => replenishBalance() } >{ t('master.payment.button') }</button>
      <div className = { s.error }>{validationErrors.amount[0] }</div>
      <p className={s.history}>{ t('master.payment.history') }</p>

      { transactions.length === 0 &&
        <EmptyEntities
          title={ t('master.payment.no_transactions') }
          isNavigateToMonitor={ false }
        />
      }

      { transactions.map((transaction, i) => (
        <div className={ s.transactionCont } key={i}>
          <span className={ s.price }>{ `${transaction.price} ${t(getLocalizedCurrency())}` }</span>
          <span className={ s.source }>{ beatifySourceString(transaction.source) }</span>
          <span className={ s.date }>{ viewDate(transaction.date) }</span>
        </div>
      ))}
    </div>
  )
})
