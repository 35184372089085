import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import s from 'styles/Profile.module.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import loginBack from 'images/login-back.svg'
import { LocaleSwitcher } from 'components/shared/LocaleSwitcher'
import master from 'store/master'
import { Menu } from 'components/shared/Menu'
import { Individual } from 'components/profile/Individual'
import { Company } from 'components/profile/Company'
import meta from 'store/meta'
import { getAll as getAllCities } from 'api/city'
import { ConfirmButton } from 'components/shared/ConfirmButton'

export const Profile = observer(() => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState('individual')
  const [cities, setCities] = useState([])

  const logout = () => {
    master.logout()
    navigate('/login')
  }

  useEffect(() => {
    meta.setLoader(true)

    getAllCities().then(data => {
      setCities(data.cities)
      meta.setLoader(false)
    })
  }, [])

  return (
    <div className={ s.profile }>
      <div className={ s.headCont }>
        <div className='container'>
          {
            (master.profile && master.isKycApproved) ? <Menu /> : <div className={ s.head }>
              <div className={ s.headItem }>
                <ConfirmButton
                  onConfirm={ logout }
                  confirmText = { t('master.profile.logout') }
                  className='border-0 bg-transparent'
                >
                  <img src={ loginBack } />
                </ConfirmButton>
              </div>
              <div className={ s.headItem }>
                <span>{ master.profile ? t('master.profile.update.title') : t('master.profile.create.title') }</span>
              </div>
              <LocaleSwitcher />
            </div>
          }
        </div>
      </div>
      <div className='container'>
        {
          !master.profile && <div className={s.typeSwitcher}>
            <button
              className={page === 'individual' ? s.active : ''}
              onClick={() => setPage('individual')}>
              { t('master.profile.individual') }
            </button>
            <button
              className={`${page === 'company' ? s.active : ''} ${master.currentOrder ? s.disabled : ''}`}
              onClick={() => setPage('company')}>
              { t('master.profile.company') }
            </button>
          </div>
        }
        { page === 'individual' ? <Individual cities={cities} /> : <Company cities={cities} /> }
      </div>
    </div>
  )
})
