import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import s from 'styles/profile/Schedule.module.css'
import company from 'store/company'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { localHHMMToUTCString } from 'services/dateFormatter'

export const Schedule = observer(({ sendSchedule, validate }) => {
  const [schedules, setSchedules] = useState([])
  const [scheduleErrors, setScheduleErrors] = useState([])
  const [scheduleMainError, setScheduleMainError] = useState(null)
  const { t } = useTranslation()
  const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

  const prepareSchedules = () => {
    let preparedSchedule = []
    for (let day = 0; day <= 6; day++) {
      let scheduleItem = company.schedule.find(item => item.day_of_week === day)
      if (scheduleItem) {
        preparedSchedule.push(
          {
            ...scheduleItem,
            is_rest: false
          }
        )

      } else {
        preparedSchedule.push({ day_of_week: day, start_at: '', end_at: '', is_rest: true })
      }
    }

    preparedSchedule = preparedSchedule.sort((a, b) => a.day_of_week === 0 ? 1 : (b.day_of_week === 0 ? -1 : a.day_of_week - b.day_of_week))
    setSchedules(preparedSchedule)
  }

  const updateSchedules = (day_of_week, field, value) => {
    const new_schedule = [...schedules]
    const index = schedules.findIndex(item => item.day_of_week === day_of_week)
    new_schedule[index] = { ...new_schedule[index], [field]: value }
    setSchedules(new_schedule)
  }

  const convertScheduleToBackend = (schedules) => {
    return schedules
      .filter(item => !item.is_rest)
      .map(item => {
        return {
          start_at: localHHMMToUTCString(item.start_at),
          end_at: localHHMMToUTCString(item.end_at),
          day_of_week: item.day_of_week
        }
      })
  }

  const removeIsRest = (schedules) => {
    return schedules
      .filter(item => !item.is_rest)
      .map(item => {
        return {
          start_at: item.start_at,
          end_at: item.end_at,
          day_of_week: item.day_of_week
        }
      })
  }

  const validateSchedules = () => {
    if (!schedules.length) return
    setScheduleMainError(null)

    let errors = []
    for (const item of schedules) {
      if (item.is_rest) {
        continue
      }

      if (!timeFormatRegex.test(item.start_at) || !timeFormatRegex.test(item.end_at)) {
        errors.push({ day: item.day_of_week, error: t('master.profile.schedule.e_format') })
        continue
      }

      if (item.start_at > item.end_at) {
        errors.push({ day: item.day_of_week, error: t('master.profile.schedule.e_difference') })
        continue
      }

      if (item.start_at === item.end_at) {
        errors.push({ day: item.day_of_week, error: t('master.profile.schedule.e_equal') })
        continue
      }

      if (item.start_at < '05:00') {
        errors.push({ day: item.day_of_week, error: t('master.profile.schedule.e_curfew_start') })
        continue
      }

      if (item.end_at > '24:00') {
        errors.push({ day: item.day_of_week, error: t('master.profile.schedule.e_curfew_end') })
      }
    }

    setScheduleErrors(errors)

    if (errors.length) {
      sendSchedule({ data: [], valid: false })
      return
    }

    const data = removeIsRest(schedules)
    if (data.length === 0) {
      setScheduleMainError(t('master.profile.schedule.error_no_day'))
      sendSchedule({ data: [], valid: false })
      return
    }

    const backend_data = convertScheduleToBackend(schedules)
    sendSchedule({ data: data, backend_data: backend_data, valid: true })
  }

  useEffect(() => {
    prepareSchedules()
  }, [company.schedule])

  useEffect(() => {
    validateSchedules()
  }, [validate])

  return (
    <div className={s.schedule}>
      <h2>{ t('master.profile.schedule.title') }</h2>
      <div className={ s.error }>{ scheduleMainError }</div>
      <ul>
        { schedules.map(schedule => (
          <li key={ schedule.day_of_week }>
            <p>{ t(`master.profile.schedule.${schedule.day_of_week}`) }</p>
            <div className={`${s.rest} ${s.before400None}`}>
              <input
                id={`${schedule.day_of_week}`}
                className={s.checkboxCustom}
                type="checkbox"
                checked={ schedule.is_rest }
                onChange={ e => updateSchedules(schedule.day_of_week, 'is_rest', !schedule.is_rest) }
              />
              <label htmlFor={`${schedule.day_of_week}`} className={s.checkboxCustomLabel}>{ t('master.profile.schedule.rest') }</label>
            </div>
            <div className={s.scheduleItem}>
              <div className={`textOnInput ${s.inputHolder}`}>
                <label htmlFor='address' className={s.back1}>{ t('master.profile.schedule.start') }</label>
                { schedule.is_rest ?
                  <input
                    type='string'
                    value='🌴 00:00'
                    disabled={ true }
                    className='disabledInput'
                  /> :
                  <InputMask
                    mask='99:99'
                    maskChar="_"
                    onChange={ e => updateSchedules(schedule.day_of_week, 'start_at', e.target.value) }
                    value={ schedule.start_at }
                    alwaysShowMask={true}
                  />
                }
              </div>
              <div className={`textOnInput ${s.inputHolder}`}>
                <label htmlFor='address' className={s.back2}>{ t('master.profile.schedule.end') }</label>
                { schedule.is_rest ?
                  <input
                    type='string'
                    value='🌴 00:00'
                    disabled={ true }
                    className='disabledInput'
                  /> :
                  <InputMask
                    mask='99:99'
                    maskChar="_"
                    onChange={ e => updateSchedules(schedule.day_of_week, 'end_at', e.target.value) }
                    value={ schedule.end_at }
                    alwaysShowMask={true}
                  />
                }
              </div>
              <div className={`${s.rest} ${s.after400Block}`}>
                <input
                  id={`${schedule.day_of_week}`}
                  className={s.checkboxCustom}
                  type="checkbox"
                  checked={ schedule.is_rest }
                  onChange={ e => updateSchedules(schedule.day_of_week, 'is_rest', !schedule.is_rest) }
                />
                <label htmlFor={`${schedule.day_of_week}`} className={s.checkboxCustomLabel}>{ t('master.profile.schedule.rest') }</label>
              </div>
            </div>
            <div className={ s.error }>{ scheduleErrors.find(item => item.day === schedule.day_of_week)?.error }</div>
          </li>
        ))}
      </ul>
    </div>
  )
})
