import { React, useEffect } from 'react'
import meta from 'store/meta'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const ConfirmButton = observer(({ onConfirm, onConfirmArgs = [], confirmText = '', className, children }) => {
  const { t } = useTranslation()

  const text = () => {
    return confirmText.length ? confirmText : t('are_you_sure')
  }

  const handleConfirmModal = () => {
    meta.setConfirmModal(true, text())
    meta.setConfirmButtonArgs(onConfirmArgs)
  }

  useEffect(() => {
    if (meta.isConfirmed) {
      meta.setConfirmModal(false)
      meta.setIsConfirmed(false)
      onConfirm(...meta.confirmButtonArgs)
      meta.setConfirmButtonArgs([])
    }
  }, [meta.isConfirmed])

  return (
    <button className={ className } onClick={ handleConfirmModal }>{ children }</button>
  )
})
