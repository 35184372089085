import { Outlet } from 'react-router-dom'
import { React } from 'react'
import s from 'styles/shared/PageStructure.module.css'
import { observer } from 'mobx-react-lite'
import master from 'store/master'
import { MenuNavigation } from 'components/shared/MenuNavigation'

export const PageStructure = observer(() => {
  return (
    <div className={ s.pageStructure }>
      <MenuNavigation />
      <div className={ `${s.pageContentCont} ${master.isKycApproved ? '' : s.byCenter }` }>
        <div className={ s.pageContent }>
          <Outlet />
        </div>
      </div>
    </div>
  )
})
