import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'

const getImage = (entity) => {
  const query = () => {
    return fetch(`${API_URL}/images?entity=${entity}`, {
      headers: {
        'Accept-Language': 'ua',
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
    })
  }

  return backOffCall(query)
}

const getPresignedUrl = () => {
  const query = () => {
    return fetch(`${API_URL}/images/presigned_url`, {
      headers: {
        'Accept-Language': 'ua',
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const uploadToBucket = (url, file) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file
  })
}

export {
  getImage,
  getPresignedUrl,
  uploadToBucket
}
