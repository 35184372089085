import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'components/shared/Menu'
import s from 'styles/About.module.css'

export const About = observer(() => {
  return (
    <div className={`${s.about} container`}>
      <Menu />
    </div>
  )
})
