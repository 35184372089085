import React from 'react'
import s from 'styles/shared/Autocomplete.module.css'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Autocomplete = ({ isLoaded, defaultValue, sendAddressData }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    init,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
    requestOptions: {
      componentRestrictions: {
        country: 'UA'
      },
    },
    callbackName: 'usePlacesAutocomplete',
  })

  const { t } = useTranslation()

  const handleInput = (e) => {
    setValue(e.target.value)
    sendAddressData({
      address: e.target.value,
      lat: '',
      lng: ''
    })
  }

  const handleSelect =
    ({ description }) =>
      () => {
        setValue(description, false)
        clearSuggestions()
        getGeocode({ address: description })
          .then((results) => {
            return getLatLng(results[0])
          })
          .then(({ lat, lng }) => {
            sendAddressData({
              address: description,
              lat: lat,
              lng: lng
            })
          })
          .catch((error) => {
            // console.log(error)
          })
      }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li className={ s.listItem } key={place_id} onClick={ handleSelect(suggestion) }>
          <strong>{main_text}</strong> <small>{ secondary_text }</small>
        </li>
      )
    })

  useEffect(() => {
    if (isLoaded) {
      init()
    }
  }, [isLoaded, init])

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue, false)
    }
  }, [defaultValue])

  return <div className={s.autocomplete}>
    <div className='textOnInput'>
      <label htmlFor='address'>{ t('master.profile.create.address') }</label>
      <input
        type = 'text'
        className = { s.input }
        value = { value }
        onChange = { handleInput }
        disabled = { !ready }
      />
      {status === 'OK' && <ul className={s.suggestions}>{renderSuggestions()}</ul>}
    </div>
  </div>
}

export { Autocomplete }
