import React from 'react'
import { Modal } from 'components/shared/Modal'
import meta from 'store/meta'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/ConfirmModal.module.css'
import { useTranslation } from 'react-i18next'

export const ConfirmModal = observer(() => {
  const { t } = useTranslation()

  return (
    <div className={s.confirmModal}>
      <Modal active={ meta.isConfirmModal } setActive={ (state) => meta.setConfirmModal(state) }>
        <h2>{ meta.confirmModalText }</h2>
        <button className={ `${s.nextButton} pressedButton` } onClick={ () => meta.setIsConfirmed(true) }>{ t('confirm') }</button>
      </Modal>
    </div>
  )
})
