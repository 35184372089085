import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/referral_program/Referrals.module.css'
import { useTranslation } from 'react-i18next'
import meta from 'store/meta'
import loginBack from 'images/login-back.svg'
import { useNavigate } from 'react-router-dom'
import master from 'store/master'
import giftImage from 'images/gift.svg'
import config from 'store/config'
import { getReferralProgramData } from 'api/master/referral_program'
import { getLocalizedCurrency } from 'services/helper'

export const Referrals = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [referrals, setReferrals] = useState(master.referrals)

  useEffect(() => {
    if (master.referrals.length) return

    meta.setLoader(true)
    getReferralProgramData()
      .then(data => {
        if (data.hasOwnProperty('referral_program')) {
          master.setReferralProgram(data.referral_program)
          master.setReferrals(data.referrals)
          setReferrals(data.referrals)
        }
        meta.setLoader(false)
      })
  }, [])

  return (
    <div className={ s.referrals }>
      <div className='container'>
        <div className={s.header}>
          <div>
            <img src={ loginBack } onClick={ () => navigate(-1) }/>
          </div>
          <div className={ s.title }>{ t('master.menu.referrals') }</div>
          <div className={ s.rightEmptyBlock }></div>
        </div>
        <div className={ s.banner }>
          <h1>{ t('master.referral_program.referrals.received', { amount: referrals.length * config.constants.REFERRAL_BONUS_AMOUNT }) } 🔥</h1>
          <p>{ `${t('master.referral_program.referrals.activated')} ${referrals.length} / ${config.constants.MAX_REFERRALS_AMOUNT}` }</p>
        </div>
        <div>
          { referrals.map((referral, i) => (
            <div className={ s.referralItem } key={i}>
              <div className={ s.firstDiv }>
                <img src={ referral?.image_url } />
                <span>{ referral?.profile?.first_name }</span>
              </div>
              <div className={ s.secondDiv }>
                <img src={ giftImage } />
                <span>{ `${config.constants.REFERRAL_BONUS_AMOUNT} ${t(getLocalizedCurrency())}` }</span>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  )
})
