import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ScrollToTop } from 'components/shared/ScrollToTop'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { observer } from 'mobx-react-lite'
import config from 'store/config'
import { Loader } from 'components/shared/Loader'
import { handleFCM, handleLocalNotificationClick } from 'services/fcm'
import master from 'store/master'

window.handleOpenURL = function(url) {
  setTimeout(function() {
    if (url.includes('payment')) {
      master.setNeedPaymentCheck(true)
    }
  }, 0)
}

let startApp = () => {
  if (window.cordova) {
    window.FirebasePlugin.onTokenRefresh(token => {
      localStorage.setItem('fcm_refreshed_token', token)
    }, error => {
      // console.log(error)
    })

    window.FirebasePlugin.onMessageReceived(function(notification) {
      handleFCM(notification)
    }, function(error) {
      // console.error(error)
    })

    cordova.plugins.notification.local.on('click', function (notification) {
      handleLocalNotificationClick(notification)
    })

    navigator.splashscreen.hide()
    screen.orientation.lock('portrait')
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <HashRouter>
      <ScrollToTop />
      <App />
    </HashRouter>
  )
}

if(!window.cordova) {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  )
} else {
  document.addEventListener('deviceready', startApp, false)
}
