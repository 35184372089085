import meta from 'store/meta'
import master from 'store/master'

const snakeToCamel = (str) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
}

const getLocalizedCurrency = () => {
  if (master.currency === undefined || master.currency === null) {
    return
  }

  return `master.services.${master.currency.toLowerCase()}`
}

const convertKeysToCamelCase = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase)
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = snakeToCamel(key)
    acc[camelKey] = convertKeysToCamelCase(obj[key])
    return acc
  }, {})
}

const showAlert = (text) => {
  meta.setShowAlert(true, text)

  setTimeout(() => {
    meta.setShowAlert(false, text)
  }, 2000)

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export {
  snakeToCamel,
  convertKeysToCamelCase,
  showAlert,
  getLocalizedCurrency
}
