import { useTranslation } from 'react-i18next'

export const useValidForm = (schema) => {
  const { t } = useTranslation()
  const validationRules = {
    isEmpty: {
      rule: (value) => {
        return !value
      },
      message: () => {
        return t('validation.errors.empty')
      }
    },

    minLength: {
      rule: (value, ruleAttribute) => {
        return value.length < ruleAttribute
      },
      message: (_, ruleAttribute) => {
        return t('validation.errors.min_length', { count: ruleAttribute })
      }
    },

    maxLength: {
      rule: (value, ruleAttribute) => {
        return value.length > ruleAttribute
      },
      message: (_, ruleAttribute) => {
        return t('validation.errors.max_length', { count: ruleAttribute })
      }
    },

    exactLength: {
      rule: (value, ruleAttribute) => {
        return value.length !== ruleAttribute
      },
      message: (_, ruleAttribute) => {
        return t('validation.errors.exact_length', { count: ruleAttribute })
      }
    },

    isEmail: {
      rule: (value) => {
        const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/gi
        return !regex.test(value)
      },
      message: () => {
        return t('validation.errors.email')
      }
    },
    onlyDigits: {
      rule: (value) => {
        const regex = /[^0-9]/g
        return regex.test(value)
      },
      message: () => {
        return t('validation.errors.digit')
      }
    },
    naturalNumber: {
      rule: (value) => {
        const regex = /^[1-9][0-9]*$/
        return !regex.test(value)
      },
      message: () => {
        return t('validation.errors.digit')
      }
    },
    minAmount: {
      rule: (value, ruleAttribute) => {
        return parseInt(value) < ruleAttribute
      },
      message: (_, ruleAttribute) => {
        return t('validation.errors.min_length', { count: ruleAttribute })
      }
    }
  }

  let currentErrorsState = null

  const validateField = (field, value) => {
    let fieldErrors = []
    for (const validation in schema[field]) {
      if (schema[field][validation] && validationRules[validation].rule(value, schema[field][validation])) {
        fieldErrors.push(validationRules[validation].message(value, schema[field][validation]))
      }
    }
    return fieldErrors
  }

  const validateFields = (fields) => {
    let errors = {}
    for (const field in fields) {
      errors[field] = validateField(field, fields[field])
    }
    currentErrorsState = errors
    return errors
  }

  const initialState = () => {
    let arr = {}
    for (const field in schema) {
      arr[field] = []
    }

    return arr
  }

  const isValid = () => {
    for (const field in currentErrorsState) {
      if (currentErrorsState[field].length !== 0) {
        return false
      }
    }

    return true
  }

  return { validateFields, initialState, isValid }
}
