import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getMasterTasks = () => {
  const query = () => {
    return fetch(`${API_URL}/master/master_tasks`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const updateMasterTasks = (id, info = null) => {
  const query = () => {
    return fetch(`${API_URL}/master/master_tasks/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        id: id,
        info: info
      })
    })
  }

  return backOffCall(query)
}

export {
  getMasterTasks,
  updateMasterTasks
}
