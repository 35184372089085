import React from 'react'
import s from 'styles/shared/LocaleSwitcher.module.css'
import { useTranslation } from 'react-i18next'
import { updateMaster } from 'api/master/master'
import master from 'store/master'
import Select from 'react-select'
import config from 'store/config'

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps}>
    <img src={require(`images/lang-${data.value}.svg`)} className={s.imageLang} />
    { label }
  </div>
)

const formatOptionLabel = ({ value }) => (
  <div>
    <img src={require(`images/lang-${value}.svg`)} className={s.imageLang} />
  </div>
)

export const LocaleSwitcher = () => {
  const { t, i18n } = useTranslation()

  const options = config.constants.LOCALES.map(locale => {
    return {
      value: locale,
      label: locale.toUpperCase(),
    }
  })

  const defaultValue = () => {
    return options.find(el => el.value === i18n.language)
  }

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.value)

    if (master.isLoggedIn()) {
      updateMaster(e.value)
    }
  }

  const customStyles = {
    dropdownIndicator: styles => ({
      ...styles,
      padding: 0,
      background: 'url("images/lang-arrow.svg")'
    }),
    control: styles => ({
      ...styles,
      background: 'transparent',
      border: 'none',
      width: '65px',
      paddingLeft: '15px',
      cursor: 'pointer',
      boxShadow: 'none !important'
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0'
    }),
    menu: styles => ({
      ...styles,
      background: '#e9fefd !important',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      fontFamily: '"Montserrat", serif',
      color: '#09131B',
      fontWeight: 500,
      fontSize: '18px',
      width: '70px',
    })
  }

  return (
    <div className={s.localeSwitcher}>
      <Select
        defaultValue={defaultValue}
        options={options}
        components={{ Option: CustomOption }}
        formatOptionLabel={formatOptionLabel}
        styles={customStyles}
        isSearchable={false}
        onChange={changeLanguage}
      />
    </div>
  )
}
