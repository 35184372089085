import config from 'store/config'
import { user } from 'store/user'
import meta from 'store/meta'

const delay = (retryCount) => new Promise(resolve => setTimeout(resolve, (2 ** retryCount) * 100))

const backOffCall = async (query, retryCount = 0) => {
  await delay(retryCount)
  return query()
    .then((response) => {
      if (retryCount >= config.constants.BACKOFF_RECONNECTION_COUNT) {
        throw new Error('time out error')
      }

      if (response.status === 401) {
        user.logout()
      }

      if (response.status == 426) {
        meta.setVersionUpgradeModal(true)
      }

      if (response.status === 500) {
        throw new Error('server is down')
      }

      if (config.constants.BACKOFF_STATUS_CODES.includes(response.status)) {
        return backOffCall(query, retryCount + 1)
      }

      if (response.headers.get('content-type') === 'text/html') {
        return response.text()
      }

      return response.json()
    }).catch(error => {
      meta.setServerDownModal(true)
      return false
    })
}

export {
  backOffCall
}
