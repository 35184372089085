import { React } from 'react'
import master from 'store/master'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/Menu.module.css'
import imageMenu from 'images/menu.svg'
import { useLocation, } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LocaleSwitcher } from 'components/shared/LocaleSwitcher'
import meta from 'store/meta'

export const Menu = observer(() => {
  const location = useLocation()
  const { t } = useTranslation()

  const getTitle = () => {
    if (location.pathname === '/') {
      return t('master.menu.monitor')
    }

    return t(`master.menu.${location.pathname.slice(1)}`)
  }

  return (
    <div className= { s.menu }>
      <div className={s.header}>
        <img className={s.menuImage} src={imageMenu} onClick={() => meta.setIsMenuOpen(true)}/>
        <div className={s.title}>{ getTitle() }</div>
        {
          location.pathname === '/profile' ? <LocaleSwitcher /> : <div className={s.rightEmptyBlock}></div>
        }
      </div>
    </div>
  )
})
