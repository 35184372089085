import master from 'store/master'
import company from 'store/company'
import meta from 'store/meta'
import { getProfile } from 'api/master/profile'

const handleFCM = (notification) => {
  if (notification.messageType === 'notification') {
    handleFCMByType(notification)
    if (notification.tap) { // when user clicks on notification in foreground

    } else { // when user is in app
      showLocalNotification(notification.body)
    }
  }
}

const handleFCMByType = (notification) => {
  switch (notification?.type) {
  case 'order':
    handleOrder(notification.data)
    break
  case 'kyc':
    handleKyc(notification.data)
    break
  case 'promo':
    handlePromo()
    break
  default:
    return
  }
}

const handleOrder = (data) => {
  master.setCurrentOrder(data.order)

  if (data.order?.state === 'initial') {
    master.navigateTo('/')
  } else if (data.order?.state === 'paid') {
    meta.setOrderConfirmedModal(true)
  }
  //TODO: discuss when client does not pay (create new order or set state to initial and continue with it )
  // else if (data.order?.state === 'canceled')
}

const handleKyc = (data) => {
  if (data === 'approved') {
    master.navigateTo('/')
    window.location.reload()
  }

  if (data === 'failed') {
    master.navigateTo('/kyc')
    window.location.reload()
  }
}

const handlePromo = () => {
  getProfile()
    .then(data => {
      if (data?.company?.balance) {
        company.updateAvailableBalance(data.company.balance.available_amount)
      }
    })
}

const showLocalNotification = (text) => {
  if (typeof text !== 'string') return

  cordova.plugins.notification.local.schedule({
    title: 'Terminovo',
    text: text,
    foreground: true,
    vibrate: true
  })
}

const handleLocalNotificationClick = (text) => {
  if (typeof text !== 'string') return

  master.navigateTo('/')
}

export {
  handleFCM,
  handleLocalNotificationClick
}
