import React, { useEffect, useRef } from 'react'

export const AutoResizingTextarea = ({ value, onChange, className }) => {
  const textareaRef = useRef(null)

  const autoResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  useEffect(() => {
    autoResize()
  }, [value])

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      className={className}
    />
  )
}
