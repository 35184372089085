import { React } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/modals/OrderConfirmedModal.module.css'
import meta from 'store/meta'
import master from 'store/master'
import { Modal } from 'components/shared/Modal'
import imageStarGreen from 'images/star-green.svg'
import imageStarBlack from 'images/star-black.svg'
import { UTCDateStringToLocalHHMM, UTCDateStringToLocalMMDDYYYY } from 'services/dateFormatter'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const OrderConfirmedModal = observer(({ order = master.currentOrder }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const toCalendar = () => {
    navigate('/calendar')
    meta.setOrderConfirmedModal(false)
  }

  return (
    <Modal active={ meta.orderConfirmedModal } setActive={ (state) => meta.setOrderConfirmedModal(state) }>
      <div className={ s.orderConfirmedModal }>
        <div className={ s.orderHead }>
          <img src={imageStarGreen} className={s.starGreen} />
          <img src={imageStarBlack} className={s.starBlack} />
          <h2>{ t('client.company.accepted_title') }</h2>
        </div>
        <div className={ s.orderBody }>
          <span>{ t('client.company.date') }</span>
          <p className={ s.bold }>{ `${UTCDateStringToLocalHHMM(order?.start_at)}-${UTCDateStringToLocalHHMM(order?.end_at)}, ${UTCDateStringToLocalMMDDYYYY(order?.start_at)}` }</p>
          <span>{ t('master.monitor.address') }</span>
          <p className={ s.bold }>{ order?.address }</p>
          <span>{ t('master.monitor.contact_info') }</span>
          <p className={ s.bold }>{ `+${order?.client?.phone_number}, ${order?.client?.profile?.first_name}` }</p>
          <span>{ t('master.monitor.description') }</span>
          <p>{ order?.service_request?.prompt }</p>
          <button className='pressedButton' onClick={ () => toCalendar() }>{ t('master.monitor.to_calendar') }</button>
        </div>
      </div>
    </Modal>
  )
})
