import { observable, action } from 'mobx'
import { createFCMData, updateFCMData } from 'api/fcm'
import company from 'store/company'

const userProps = {
  id: observable,
  token: observable,
  role: observable,
  logout: action,
  isLoggedIn: action,
  setInitialState: action,
  handleFCMToken: action,
}

const getUserFromLS = () => {
  try {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user.hasOwnProperty('role') && user.hasOwnProperty('id') && user.hasOwnProperty('token')) {
      return user
    }
  } catch (error) {
    localStorage.removeItem('user')
    return null
  }
}

class User {
  id = getUserFromLS()?.id
  token = getUserFromLS()?.token
  role = getUserFromLS()?.role

  setInitialState(user) {
    this.setUserToLS(user)
    this.id = user.id
    this.token = user.token
    this.role = user.role
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('fcm_data')
    this.token = null
    this.id = null
    this.role = null
    this.phone = null
    this.profile = null
    this.orders = []
    this.city = null
    this.services = null
    this.balance = null
    this.approved = null
    this.blocked = null
    this.referralProgram = null
    this.referrals = []
    this.tasks = []
    this.kycs = []

    company.clearState()
  }

  isLoggedIn(role) {
    return !!this.token && this.role === role
  }

  setUserToLS(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  handleFCMToken() {
    let FCMRefreshedToken = localStorage.getItem('fcm_refreshed_token')
    if (!FCMRefreshedToken) return

    let FCMData = JSON.parse(localStorage.getItem('fcm_data'))?.fcm_data

    if (!FCMData) {
      createFCMData(FCMRefreshedToken)
        .then(data => {
          if (data.fcm_data) {
            localStorage.setItem('fcm_data', JSON.stringify(data))
          }
        })
    } else if (FCMData?.token !== FCMRefreshedToken) {
      updateFCMData(FCMRefreshedToken, FCMData.id)
        .then(data => {
          if (data.fcm_data) {
            localStorage.setItem('fcm_data', JSON.stringify(data))
          }
        })
    }
  }
}

const user = new User()

export { User, userProps, user }
