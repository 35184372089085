import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'components/shared/Menu'
import { getOrders } from 'api/master/order'
import { UTCDateStringToLocalHHMM, UTCDateStringToLocalMMDDYYYY } from 'services/dateFormatter'
import s from 'styles/Orders.module.css'
import { useTranslation } from 'react-i18next'
import meta from 'store/meta'
import { getLocalizedCurrency } from 'services/helper'
import { EmptyEntities } from 'components/shared/EmptyEntities'

export const Orders = observer(() => {
  const STATES = ['completed', 'canceled', 'paid', 'accepted']
  const [orders, setOrders] = useState([])
  const { t } = useTranslation()
  const stateClass = (state) => {
    switch (state) {
    case 'completed':
      return s.completed
    case 'canceled':
      return s.canceled
    case 'initial':
      return s.initial
    case 'paid':
      return s.paid
    case 'accepted':
      return s.accepted
    default:
      return s.completed
    }
  }

  useEffect(() => {
    meta.setLoader(true)
    getOrders(STATES)
      .then(data => {
        if (data.orders) {
          setOrders(data.orders)
        }
        meta.setLoader(false)
      })
  }, [])

  return (
    <div className={`${s.orders} container`}>
      <Menu />
      <div>
        { !orders.length ?
          <div className={ s.emptyCont }>
            <EmptyEntities
              title={ t('master.orders.no_orders') }
              isNavigateToMonitor={ true }
            />
          </div> :
          orders.map(order => (
            <div className={ s.order } key={ Math.random() }>
              <div className={ s.orderHead }>
                <div className={ s.topHolder }>
                  <span className={ s.priceTitle }>{ UTCDateStringToLocalMMDDYYYY(order.start_at) }</span>
                  <span className={ `${stateClass(order.state)} ${s.orderState}` }>{ t(`order.state.${order.state}`) }</span>
                </div>
                <p>{ `${ t('master.orders.order') } #${ order.id?.slice(-12) }` }</p>
              </div>
              <div className={ s.orderSubHead }>
                <div className={ s.div1 }>
                  <span className={ s.span1 }>{ t('master.orders.commission') }</span>
                  <span className={ s.span2 }>{ order.fee } { t(getLocalizedCurrency()) }</span>
                  <span className={ s.span3 }>≈ { order.start_price} { t(getLocalizedCurrency()) } { t('master.orders.price') }</span>
                </div>
                <div className={ s.div2 }>
                  <span className={ s.span1 }>{ t('master.monitor.time') }</span>
                  <span className={ s.span2 }>{ `${UTCDateStringToLocalHHMM(order.start_at)}-${UTCDateStringToLocalHHMM(order.end_at)}` }</span>
                </div>
              </div>
              <div className={ s.orderBody }>
                <span>{ t('master.monitor.address') }</span>
                <p className={ s.bold }>{ order.address }</p>
                <span>{ t('master.monitor.contact_info') }</span>
                <p className={ s.bold }>{ `+${order?.client?.phone_number}, ${order?.client?.profile?.first_name}` }</p>
                <span>{ t('master.monitor.description') }</span>
                <p>{ order.service_request?.prompt }</p>
              </div>
            </div>
          ))}
        <br/>
      </div>
    </div>
  )
})
