import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const createFCMData = (FCMToken = '') => {
  const query = () => {
    return fetch(`${API_URL}/fcm_data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        fcm_token: FCMToken
      })
    })
  }

  return backOffCall(query)
}

const updateFCMData = (FCMToken = '', id = '') => {
  const query = () => {
    return fetch(`${API_URL}/fcm_data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        fcm_token: FCMToken,
        fcm_id: id
      })
    })
  }

  return backOffCall(query)
}

export {
  createFCMData,
  updateFCMData
}
