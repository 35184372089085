import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/MenuNavigation.module.css'
import imageMenuClose from 'images/menu-close.svg'
import whiteYellowStar from 'images/white-yellow-star.svg'
import yellowGreenStar from 'images/yellow-green-star.svg'
import master from 'store/master'
import company from 'store/company'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import meta from 'store/meta'
import { updateCompany } from 'api/company'
import techSupport from 'services/techSupport'
import { showAlert, getLocalizedCurrency } from 'services/helper'
import { ConfirmButton } from 'components/shared/ConfirmButton'
import { isFeatureEnabled } from 'api/flipper'

export const MenuNavigation = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(company.active)

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      meta.setIsMenuOpen(false)
      let iframe = document.getElementsByName('helpcrunch-iframe')[0]
      if (iframe) iframe.style.opacity = '0'
    }
  }

  const handleNav = (page) => {
    if (location.pathname === page) {
      closeMenu()

      return
    }

    if (['/orders', '/profile', '/payment', '/calendar', '/service,', '/', '/bonus'].includes(page)) meta.setLoader(true)
    closeMenu()
    navigate(page)
  }

  const logout = () => {
    let iframe = document.getElementsByName('helpcrunch-iframe')[0]
    iframe.style.opacity = '0'
    iframe.style.transform = 'translateX(-100vw)'
    master.logout()
    document.body.style.overflow = 'auto'
    navigate('/login')
  }

  const handleCompanyEnable = (value) => {
    isFeatureEnabled('order_monitor')
      .then(data => {
        if (data) {
          setIsActive(value)
          updateCompany({ active: value })
        } else {
          showAlert(t('master.menu.enable_alert'))
        }
        meta.setLoader(false)
      })
  }

  const handleTechSupportIframe = () => {
    let iframe = document.getElementsByName('helpcrunch-iframe')[0]
    if (iframe) {
      if (meta.isMenuOpen) {
        iframe.style.opacity = '1'
        iframe.style.transform = 'none'
      } else {
        iframe.style.transform = 'translateX(-100vw)'
      }
    }
    if (meta.isMenuOpen && !iframe) {
      techSupport.initIframe()
    }
  }

  const handleResize = () => {
    if (window.innerWidth > 767 && master.isKycApproved) {
      meta.setIsMenuOpen(true)
      return
    }

    meta.setIsMenuOpen(false)
  }

  useEffect(() => {
    handleResize()
  }, [master.isKycApproved])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsActive(company.active)
  }, [company.active])

  useEffect(() => {
    handleTechSupportIframe()
  }, [meta.isMenuOpen])

  useEffect(() => {
    let startTouchX = 0
    let endTouchX = 0
    let startTouchY = 0
    let endTouchY = 0

    document.addEventListener('touchstart', (event) => {
      startTouchX = event.changedTouches[0].pageX
      startTouchY = event.changedTouches[0].pageY
    })

    document.addEventListener('touchend', (event) => {
      endTouchX = event.changedTouches[0].pageX
      endTouchY = event.changedTouches[0].pageY
      if (Math.abs(endTouchY - startTouchY) < 40 && endTouchX > startTouchX + 80) {
        meta.setIsMenuOpen(true)
      }
      if (Math.abs(endTouchY - startTouchY) < 40 && endTouchX < startTouchX - 80) {
        closeMenu()
      }
    })
  }, [])

  return (
    <div className= { s.mainMenu }>
      <div className={`${meta.isMenuOpen ? s.active : ''} ${s.menuModal} container`} >
        <div className={s.header}>
          <img className={s.imageMenuClose} src={imageMenuClose} onClick={() => closeMenu()}/>
          <div>
            <span className={s.balance}>{ t('master.menu.balance') }</span>
            <span className={s.price}>{company?.balance?.available_amount} { t(getLocalizedCurrency()) }</span>
          </div>
        </div>
        <div className={ s.bonusButton } onClick={ () => { handleNav('/bonus') } }>
          <img className={ s.firstImg } src={ whiteYellowStar }/>
          <img className={ s.lastImg } src={ yellowGreenStar }/>
          <button className='pressedButton'><span>{ t('master.menu.bonus_button') }</span></button>
        </div>
        <div className={s.main}>
          <ul>
            <div className={ s.monitorCont }>
              <li
                onClick={ () => { handleNav('/') } }
                className={ location.pathname === '/' ? s.activeLink : '' }
              >
                { t('master.menu.monitor') }
              </li>
              <div>
                <input
                  type='checkbox'
                  id='highload1'
                  className={s.highload1}
                  name="highload1"
                  checked={isActive}
                  onChange={ () => handleCompanyEnable(!isActive) }
                />
                <label htmlFor='highload1' className={ s.lb1 }></label>
              </div>
            </div>
            <li
              className={ location.pathname === '/calendar' ? s.activeLink : '' }
              onClick={ () => { handleNav('/calendar') } }
            >
              { t('master.menu.calendar') }
            </li>
            <li
              className={ location.pathname === '/service' ? s.activeLink : '' }
              onClick={ () => { handleNav('/service') } }
            >
              { t('master.menu.service') }
            </li>
            <li
              onClick={ () => { handleNav('/payment') } }
              className={ location.pathname === '/payment' ? s.activeLink : '' }
            >
              { t('master.menu.payment') }
            </li>
            <li
              onClick={ () => { handleNav('/orders') } }
              className={ location.pathname === '/orders' ? s.activeLink : '' }
            >
              { t('master.menu.orders') }
            </li>
            <li
              onClick={ () => { handleNav('/profile') } }
              className={ location.pathname === '/profile' ? s.activeLink : '' }
            >
              { t('master.menu.profile') }
            </li>
            <ConfirmButton
              onConfirm={ logout }
              confirmText = { t('master.profile.logout') }
              className='border-0 p-0 bg-transparent'
            >
              <li >{ t('master.menu.logout') }</li>
            </ConfirmButton>
          </ul>
        </div>
      </div>
    </div>
  )
})
