import React from 'react'
import s from 'styles/Loader.module.css'
import imageT from 'images/t.svg'
import meta from 'store/meta'

export const Loader = () => {

  return (
    <div className={s.loader}>
      <div className={s.bodyCont}>
        { meta.loaderText === '' ? null : <h3>{ meta.loaderText }</h3> }
        <div className={s.loaderCont}>
          <div className={s.barsCont}>
            <span className={s.bar1}></span>
            <span className={s.bar2}></span>
            <span className={s.bar3}></span>
          </div>
          <div className={s.letterCont}>
            <img src={imageT} />
          </div>
        </div>
      </div>
    </div>
  )
}
