import React from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/Login.module.css'
import validationSchema from 'services/validation/schemas/login.json'
import { useState } from 'react'
import { useValidForm } from 'services/validation/validForm'
import auth from 'api/auth.js'
import { CodeConfirmation } from 'components/shared/CodeConfirmation'
import meta from 'store/meta'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import PhoneMask from 'services/phoneMask'
import { useNavigate } from 'react-router-dom'
import logo from '../images/logo.svg'
import { LocaleSwitcher } from './shared/LocaleSwitcher'
import imageStarPurple from 'images/star-purple.svg'
import imageStarBlack from 'images/star-black.svg'
import loginBack from 'images/login-back.svg'
import master from '../store/master'

export const Login = observer(() => {
  const [phone, setPhone] = useState('')
  const [resourceId, setResourceId] = useState(null)
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [mask, setMask] = useState(PhoneMask.getMask('UA'))
  const { t } = useTranslation()
  const navigate = useNavigate()

  const preAuthenticate = (e) => {
    e.preventDefault()
    setValidationErrors(validForm.validateFields({ phone: PhoneMask.onlyDigits(phone) }))
    if (validForm.isValid()) {
      meta.setLoader(true)
      auth.preAuthenticate(PhoneMask.onlyDigits(phone), 'master')
        .then(data => {
          meta.setLoader(false)
          if (data.hasOwnProperty('resource_id')) {
            setResourceId(data.resource_id)
          } else if (data.hasOwnProperty('errors')) {
            setValidationErrors({ phone: [data.errors.server] })
          }
        })
    }
  }

  const authenticate = (code) => {
    meta.setLoader(true)
    auth.authenticate(resourceId, code, 'master')
      .then(data => {
        if (data.hasOwnProperty('token')) {
          processUser('master', data)
        } else if (data.hasOwnProperty('errors')) {
          setValidationErrors(null)
          setTimeout(() => setValidationErrors({ code: data.errors.server }), 0)
          meta.setLoader(false)
        }
      })
  }

  const processUser = (userRole, data) => {
    const userInitialData = {
      role: userRole,
      id: data.resource_id,
      token: data.token
    }
    master.setInitialState(userInitialData)
    navigate('/')
  }

  return (
    <div className={`${s.login} container`}>
      <div className={ s.head }>
        {resourceId && (
          <div className={ s.headItem }>
            <img src={ loginBack } onClick={ () => setResourceId(null) }/>
          </div>
        )}
        <div className={ resourceId ? s.headItem : '' }>
          <img className={ s.logo } src={logo} />
        </div>
        <LocaleSwitcher />
      </div>
      {resourceId ?
        <CodeConfirmation
          sendCallBack = { (code) => authenticate(code) }
          ResendCallBack = { (e) => preAuthenticate(e) }
          errors = { validationErrors?.code }
        />
        : <div>
          <img src={imageStarPurple} className={s.starPurple} />
          <img src={imageStarBlack} className={s.starBlack} />
          <h2 className={s.loginTitle}><span>{ t('login.title') }</span></h2>
          <p>{ t('login.description') }</p>
          <form>
            <InputMask
              mask={ mask }
              onChange={ e => setPhone(e.target.value) }
              value={ phone }
              alwaysShowMask={true}
            />
            <div className = { s.error }>{ validationErrors.phone[0] }</div>
            <button className={ `${s.nextButton} pressedButton` } onClick={ e => preAuthenticate(e) }>{ t('login.submit_master') }</button>
          </form>
        </div>
      }
    </div>
  )
})
