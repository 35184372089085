import { React } from 'react'
import s from 'styles/shared/EmptyEntities.module.css'
import sunImg from 'images/calendar/sun.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const EmptyEntities = ({ title, isNavigateToMonitor = false }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className={s.emptyEntities}>
      <img src={ sunImg } />
      <h1>{ title }</h1>
      { isNavigateToMonitor && <p onClick={ () => navigate('/') } >{ t('master.calendar.check_monitor') }</p> }
    </div>
  )
}
