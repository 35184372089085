import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import i18n from 'config/i18n'

const getAll = () => {
  const query = () => {
    return fetch(`${API_URL}/cities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

export {
  getAll
}
