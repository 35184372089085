import React from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/referral_program/Create.module.css'
import { useState } from 'react'
import meta from 'store/meta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import logo from 'images/logo.svg'
import { LocaleSwitcher } from 'components/shared/LocaleSwitcher'
import imageStarPurple from 'images/star-purple.svg'
import imageStarBlack from 'images/star-black.svg'
import { createReferralProgram } from 'api/master/referral_program'
import { showAlert } from 'services/helper'

export const Create = observer(() => {
  const [referredBy, setReferredBy] = useState('')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const createReferralProgramApi = (code) => {
    if (typeof code === 'string' && code?.length !== 6) return

    meta.setLoader(true)
    createReferralProgram(code)
      .then(data => {
        meta.setLoader(false)
        if (data.hasOwnProperty('errors')) {
          showAlert(data.errors.server)

          return
        }

        navigate('/profile')
      })
  }

  const handleCode = (value) => {
    let result = value.replace(/\s/g, '')
    if (result.length > 6) return

    setReferredBy(result)
  }

  return (
    <div className={`${s.referralPrograCreate} container`}>
      <div className={ s.head }>
        <div className={ s.headItem }>
          <img className={ s.logo } src={logo} />
        </div>
        <LocaleSwitcher />
      </div>
      <div>
        <img src={imageStarPurple} className={s.starPurple} />
        <img src={imageStarBlack} className={s.starBlack} />
        <h2 className={s.loginTitle}><span>{ t('master.referral_program.create.title') }</span></h2>
        <p>{ t('master.referral_program.create.quote') }</p>
        <input
          className={ s.codeInput }
          placeholder='******'
          value={ referredBy }
          onChange={ (e) => handleCode(e.target.value)}
        />

        <button
          className={ ` ${s.nextButton} pressedButton ${referredBy.length === 6 ? '' : s.notVisible}` }
          onClick={ () => createReferralProgramApi(referredBy) }>
          { t('master.referral_program.create.continue') }
        </button>
        <button className={ s.skipButton } onClick={ () => createReferralProgramApi(null) }>{ t('master.referral_program.create.skip') }</button>
      </div>
    </div>
  )
})
