import { backOffCall } from 'api/api.js'
import { API_URL } from 'config/constants'

const loadLocales = (locale) => {
  const query = () => {
    return fetch(`${API_URL}/translations`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      }
    })
  }

  return backOffCall(query)
}

export {
  loadLocales
}
