import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import s from 'styles/Kyc.module.css'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import loginBack from 'images/login-back.svg'
import addPhoto from 'images/add-photo.svg'

import { LocaleSwitcher } from 'components/shared/LocaleSwitcher'
import { Spinner } from 'components/Spinner'

import config from 'store/config'

import { getPresignedUrl, uploadToBucket } from 'api/image'
import { createKyc } from 'api/kyc'

import { showAlert } from 'services/helper'
import master from 'store/master'

export const Kyc = observer(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [identifier, setIdentifier] = useState('')
  const [image, setImage] = useState(null)
  const [isImageError, setIsImageError] = useState(false)
  
  const [isImageUploaded, setIsImageUploaded] = useState(null)

  async function preparePreSignedUrl(file) {
    setIsImageUploaded(false)
    let get_presigned_url = await getPresignedUrl()

    try {
      await uploadToBucket(get_presigned_url.url, file)
    } catch(error) {
      showAlert(t('master.kyc.size_error'))
      setIsImageError(true)
      setIsImageUploaded(null)
      setImage(null)
      return
    }

    setIdentifier(get_presigned_url.identifier)
    setIsImageUploaded(true)
  }

  const handleImageChange = (event) => {
    const img = event.target.files[0]

    if (!img) return

    if (img.size / (1024 * 1024) > config.constants.MAX_IMG_SIZE_MB) {
      showAlert(t('master.kyc.size_error'))
      setIsImageError(true)
      setImage(null)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(img)
    setIsImageError(false)
    
    setIsImageUploaded(false)
    preparePreSignedUrl(img)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createKyc('kycs', identifier)
      .then((data) => {
        if (data.hasOwnProperty('errors')) {
          showAlert(t('master.kyc.size_error'))
        } else {
          navigate('/kyc_request')
        }
      }).catch((e) => {
        showAlert(t('master.kyc.size_error'))
      })
  }

  useEffect(() => {
    if (master.kycs.some(kyc => kyc.approved === true)) {
      return navigate('/')
    }
  }, [master.kycs])

  return (
    <>
      <div className={ s.kyc }>
        <div className={ s.headCont }>
          <div className='container'>
            {
              <div className={ s.head }>
                <div className={ s.headItem }>
                  <img src={ loginBack } onClick={ () => navigate('/service') }/>
                </div>
                <div className={ s.headItem }>
                  <span>{ t('master.kyc.title') }</span>
                </div>
                <LocaleSwitcher />
              </div>
            }
          </div>
        </div>

        <div className='container'>
          <h2 className={s.kycTitle}><span>{ t('master.kyc.title') }</span></h2>
          <p>{ t('master.kyc.description') }</p>
          { isImageUploaded && <div className={ s.uploadedImage }><img width={'100%'} src={image}/></div> }
          <form>
            {       
              !(image && isImageUploaded === true) &&
                <div className={s.fileInputContainer}>
                  {
                    isImageUploaded === false ?
                      <Spinner /> :
                      <div className={s.fileInput}>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: 'none', cursor: 'pointer' }}
                          onChange={handleImageChange}
                        />
                        <div className={s.photoCont}>
                          <label htmlFor="fileInput" className={s.imageLabel}>
                            <img src={addPhoto} alt='add photo'/>
                            <span>{ t('master.kyc.photo') }</span>
                            <p className={ isImageError ? s.error : '' }> { t('master.kyc.size') }</p>
                          </label>
                        </div>
                      </div>
                  }
                </div>
            }
            { 
              isImageUploaded && 
                <div className={s.fileInput}>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />

                  <div className={s.photoCont}>
                    <label htmlFor="fileInput" className={s.imageLabel}>
                      <img src={addPhoto} alt='add photo'/>
                      <span>{ t('master.kyc.photo_again') }</span>
                      <p className={ isImageError ? s.error : '' }> { t('master.kyc.size') }</p>
                    </label>
                  </div>
                </div>
            }

            {
              isImageUploaded &&
              <button className={ `${s.nextButton} pressedButton` } onClick={ e => handleSubmit(e) } >
                { t('master.kyc.submit') }
              </button>
            }
          </form>
        </div>
      </div> 
    </>
  )
})
