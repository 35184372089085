import { React, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import uncheckedImage from 'images/round_unchecked.svg'
import checkedImage from 'images/round_checked.svg'
import s from 'styles/tasks/Show.module.css'
import { useTranslation } from 'react-i18next'
import backImg from 'images/login-back.svg'

export const Show = observer(({ task, close, updateMasterTasks }) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(null)

  const sendAnswer = () => {
    if (!selectedOption) return

    updateMasterTasks({ option: selectedOption })
  }

  return (
    <div className={ s.taskShow }>
      <div className='container'>
        <div className={ s.head }>
          <img src={ backImg } onClick={ () => close() }/>
        </div>
        <div className='container'>
          <h1>{ t(task.data.name) }</h1>
          <div className={ s.optionsCont }>
            { task.data.options.map((option, i) => (
              <div className={ s.optionItem } key={i} onClick={ () => setSelectedOption(option) }>
                <span className={ selectedOption === option ? s.checked : '' }>{ t(option) }</span>
                <img src={ selectedOption === option ? checkedImage : uncheckedImage } />
              </div>
            ))}
          </div>
        </div>
        <button className={ `${s.AddButton} pressedButton  ${selectedOption ? '' : s.disabled}` } onClick={ () => sendAnswer() }>
          { t('master.kyc.submit') }
        </button>
      </div>
    </div>
  )
})
