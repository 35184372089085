import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/Calendar.module.css'
import { Menu } from 'components/shared/Menu'
import { getEvents, createEvent } from 'api/master/event'
import {
  convertByPartsToUTCDateString,
  UTCDateStringToLocalHHMM,
  UTCDateStringToLocalMMDDYYYY
} from 'services/dateFormatter'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/shared/Modal'
import Select from 'react-select'
import { EmptyEntities } from 'components/shared/EmptyEntities'
import InputMask from 'react-input-mask'
import meta from 'store/meta'
import { useValidForm } from 'services/validation/validForm'
import validationSchema from 'services/validation/schemas/calendar.json'

export const Calendar = observer(() => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [eventsPerDays, setEventsPerDays] = useState([])
  const [events, setEvents] = useState([])
  const [isModalActive, setIsModalActive] = useState(false)
  const { t } = useTranslation()
  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const [startAt, setStartAt] = useState('')
  const [endAt, setEndAt] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [error, SetError] = useState('')
  const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

  const generateDateOptions = () => {
    const options = []
    const currentDate = new Date()

    for (let i = 0; i < 14; i++) {
      const date = new Date()
      date.setDate(currentDate.getDate() + i)
      const formattedDate = date.toISOString().split('T')[0]
      options.push({ value: date, label: formattedDate })
    }

    return options
  }

  const handleDateChange = (selectedOption) => {
    setSelectedDate(selectedOption)
  }

  const addEvent = () => {
    if (
      (!timeFormatRegex.test(startAt) ||
      !timeFormatRegex.test(endAt)) ||
      (startAt >= endAt)
    ) {
      SetError(t('master.calendar.error'))
      return
    }

    setValidationErrors(validForm.validateFields({ address, description }))
    if (validForm.isValid()) {
      meta.setLoader(true)
      SetError('')

      let event = {
        start_at: prepareDate(startAt),
        end_at: prepareDate(endAt),
        address: address,
        description: description
      }

      createEvent(event).then(data => {
        meta.setLoader(false)
        if (data?.errors?.hasOwnProperty('server')) {
          SetError(data.errors.server)

          return
        }

        prepareEvents([...events, event])
        setEvents(prev => [
          ...prev,
          event
        ])

        setIsModalActive(false)
      })
    }
  }

  const prepareDate = (time) => {
    const [year, month, day] = selectedDate.label.split('-')
    const [hours, minutes] = time.split(':').map(Number)

    return convertByPartsToUTCDateString(year, month, day, hours, minutes)
  }

  const prepareEvents = (events) => {
    const groupedEvents = {}
    events.forEach(event => {
      const date = UTCDateStringToLocalMMDDYYYY(event.start_at)
      if (!groupedEvents[date]) {
        groupedEvents[date] = {
          date: date,
          events: []
        }
      }

      const startTime = UTCDateStringToLocalHHMM(event.start_at)
      const endTime = UTCDateStringToLocalHHMM(event.end_at)
      groupedEvents[date].events.push({
        address: event.address,
        description: event.description,
        start_at: startTime,
        end_at: endTime
      })
    })

    let result = Object.values(groupedEvents)
    let currentDate = getCurrentDate()
    let foundCurrentDate = result.some((item) => {
      return item.date === currentDate
    })

    if (!foundCurrentDate) {
      result.push({ date: currentDate, events: [] })
    }

    result.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })

    result.forEach((item) => {
      item.events.sort((a, b) => {
        let timeA = new Date('1970-01-01T' + a.start_at)
        let timeB = new Date('1970-01-01T' + b.start_at)
        return timeA - timeB
      })
    })
    setEventsPerDays(result)
    meta.setLoader(false)
  }

  const getCurrentDate = () => {
    let today = new Date()

    return UTCDateStringToLocalMMDDYYYY(today.toISOString())
  }

  const getLocalisedDay = (dateString) => {
    const date = parseDate(dateString)

    return t(`master.calendar.${date.getDay()}`)
  }

  const getDay = (dateString) => {
    const date = parseDate(dateString)

    return date.getDate()
  }

  const parseDate = (dateString) => {
    return new Date(dateString)
  }

  useEffect(() => {
    meta.setLoader(true)
    setSelectedDate(generateDateOptions()[0])
    document.body.style.background = 'white'
    getEvents().then(data => {
      setEvents(data.events)
      prepareEvents(data.events)
    })

    return () => {
      document.body.style.background = 'linear-gradient(346.75deg, #CBE8FF -71.58%, #ECFFFD 96.22%)'
    }
  }, [])

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    control: (provided, state) => ({
      ...provided,
      height: 48,
      fontSize: 14,
      display: 'flex',
      flexWrap: 'nowrap',
      marginTop: 20,
      border: state.isFocused ? '1px solid #a4bccf !important' : '1px solid #a4bccf !important',
      borderRadius: 8,
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#a4bccf' : '#a4bccf'
      },
    })
  }

  return (
    <div className={`${s.calendar} container`}>
      <Menu />
      <div className={ s.mainCont }>
        <div className={s.allEventsCont}>
          { eventsPerDays.map((day, i) => (
            <div className={s.eventsPerDaysCont} key={i}>
              <div className={`${s.dayCont} ${i === 0 ? s.dayContFirst : ''}`}>
                <span>{ getLocalisedDay(day.date) }</span>
                <span>{ getDay(day.date) }</span>
              </div>
              <div className={s.eventsCont}>
                { i === 0 && day.events.length === 0 && <div>
                  <p>{ t('master.calendar.no_events') }</p>
                  <div className={ s.noOrdersLine }><span></span></div>
                </div>
                }
                { day.events.map((event, j) => (
                  <div className={s.eventCont} key={j}>
                    <div className={s.eventBody}>
                      <span>{ event.address }</span>
                      <span>{ event.description }</span>
                    </div>
                    <div className={s.eventTime}>
                      <span>{`${event.start_at}-${event.end_at}`}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        { events.length === 0 &&
          <EmptyEntities
            title={ t('master.calendar.no_events_title') }
            isNavigateToMonitor={ true }
          />
        }
      </div>
      <button className={ `${s.AddButton} pressedButton` } onClick={ () => setIsModalActive(true) }>
        { t('master.calendar.add_event') }
      </button>

      <Modal active={ isModalActive } setActive={ (state) => setIsModalActive(state) }>
        <h2>{ t('master.calendar.add_event') }</h2>
        <form>
          <div className='textOnInput'>
            <label>{ t('master.calendar.description') }</label>
            <input
              type='text'
              onChange={ e => setDescription(e.target.value) }
              value={ description }
              name='description'
            />
          </div>
          <div className={ s.error }>{ validationErrors.description }</div>
          <div className='textOnInput'>
            <label>{ t('master.profile.create.address') }</label>
            <input
              type='text'
              onChange={ e => setAddress(e.target.value) }
              value={ address }
              name='address'
            />
          </div>
          <div className={ s.error }>{ validationErrors.address }</div>
          <div className='textOnInput'>
            <label className={s.selectLabel}>{ t('master.calendar.day') }</label>
            <Select
              value={selectedDate}
              onChange={handleDateChange}
              options={generateDateOptions()}
              styles={customStyles}
            />
          </div>
          <div className='textOnInput'>
            <label>{ t('master.calendar.start_at') }</label>
            <InputMask
              mask='99:99'
              maskChar="_"
              onChange={ e => setStartAt(e.target.value) }
              value={ startAt }
              name='start'
              alwaysShowMask={ true }
            />
          </div>
          <div className='textOnInput'>
            <label>{ t('master.calendar.end_at') }</label>
            <InputMask
              mask='99:99'
              maskChar="_"
              onChange={ e => setEndAt(e.target.value) }
              value={ endAt }
              name='start'
              alwaysShowMask={ true }
            />
          </div>
          <p className={s.error}>{ error }</p>
        </form>
        <button className={ `${s.AddButton} pressedButton` } onClick={ () => addEvent() } >{ t('confirm') }</button>
      </Modal>
    </div>
  )
})
