import { React, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'components/shared/Menu'
import s from 'styles/Bonus.module.css'
import { Index as ReferralProgram } from './referral_program/Index'
import { Index as Task } from './tasks/Index'
import meta from 'store/meta'
import { getMasterTasks } from 'api/master/master_task'
import { getReferralProgramData } from 'api/master/referral_program'
import master from 'store/master'

export const Bonus = observer(() => {
  const [tasks, setTasks] = useState([])
  const [referralProgramData, setReferralProgramData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      meta.setLoader(true)
      try {
        const [masterTasksData, referralProgramData] = await Promise.all([
          getMasterTasks(),
          getReferralProgramData()
        ])

        if (masterTasksData.hasOwnProperty('master_tasks')) {
          master.setTasks(masterTasksData.master_tasks)
          setTasks(masterTasksData.master_tasks)
        }

        if (referralProgramData.hasOwnProperty('referral_program')) {
          setReferralProgramData(referralProgramData)
        }
      } catch (error) {
        meta.setLoader(false)
      } finally {
        meta.setLoader(false)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={`${s.bonus} container`}>
      <Menu />
      <ReferralProgram referralProgramData={ referralProgramData } />
      <Task tasks={ tasks }/>
    </div>
  )
})
