import { React, useEffect, useRef } from 'react'
import s from 'styles/shared/Modal.module.css'
import closeIcon from 'images/close-icon.svg'
export const Modal = ({ active, setActive, children, needCancelBtn = true }) => {
  const modalContentRef = useRef(null)

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
      requestAnimationFrame(() => {
        if (modalContentRef.current) {
          modalContentRef.current.classList.add(s.active)
        }
      })
    } else {
      document.body.style.overflow = 'auto'
      if (modalContentRef.current) {
        modalContentRef.current.classList.remove(s.active)
      }
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <div
      className={`${s.modal} ${active ? s.active : ''}`}
      onClick={() => needCancelBtn && setActive(false)}
    >
      <div
        ref={modalContentRef}
        className={s.modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        { 
          needCancelBtn && <span className={s.closeButtonMobile} onClick={() => setActive(false)}>
            <img src={closeIcon} alt="close icon" />
          </span> 
        }

        {children}
      </div>
    </div>
  )
}
