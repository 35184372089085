import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'

const getConstants = () => {
  const query = () => {
    return fetch(`${API_URL}/master/constants`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

export {
  getConstants
}
