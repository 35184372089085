import React from 'react'
import s from 'styles/shared/CodeConfirmation.module.css'
import config from 'store/config'
import { useState, useEffect } from 'react'
import validationSchema from 'services/validation/schemas/code_confirmation.json'
import { useValidForm } from 'services/validation/validForm'
import meta from 'store/meta'
import { useTranslation } from 'react-i18next'
import imageStarPurple from 'images/star-purple.svg'
import imageStarBlack from 'images/star-black.svg'

export const CodeConfirmation = ({ sendCallBack, ResendCallBack, errors }) => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [code, setCode] = useState('')
  const [counter, setCounter] = useState(config.constants.SMS_RESEND_INTERVAL)
  const [intervalId, setIntervalId] = useState(null)
  const { t } = useTranslation()

  const resendCode = (e) => {
    e.preventDefault()
    ResendCallBack(e)
    setCounter(config.constants.SMS_RESEND_INTERVAL)
    decrease()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setValidationErrors(validForm.validateFields({ code }))
    if (validForm.isValid()) {
      meta.setLoader(true)
      sendCallBack(code)
    }
  }

  const decrease = () => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 0) {
          clearInterval(interval)
          return 0
        }
        return prevCounter - 1
      })

    }, 1000)
    setIntervalId(interval)
    return interval
  }

  useEffect(() => {
    meta.setLoader(false)
  }, [ResendCallBack])

  useEffect(() => {
    const interval = decrease()
    return () => {
      clearInterval(intervalId)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    meta.setLoader(false)
    if (errors) setValidationErrors({ code: errors })
  }, [errors])

  return (
    <div className={s.confirmation}>
      <>
        <img src={imageStarPurple} className={s.starPurple} />
        <img src={imageStarBlack} className={s.starBlack} />
        <h2 className={s.confirmTitle}>{ t('confirmation.title') }</h2>
        <p>{ t('confirmation.description') }</p>
        <form>
          <input
            onChange = { e => setCode(e.target.value) }
            value = { code } name='code' type='number'
            placeholder = { t('confirmation.placeholder.code') }
          />
          <div className = { s.error }>{validationErrors.code }</div>
          <button className={ `${s.confirmButton} pressedButton` } onClick = { e => handleSubmit(e) }>{ t('confirmation.submit') }</button>
          <button
            className = { `${s.resendButton} ${!!counter ? s.disabledButton : ''}`}
            onClick = { e => resendCode(e) }
            disabled = { !!counter }
          >
            { t('confirmation.resend') } { !!counter ? counter : '' }
          </button>
        </form>
      </>
    </div>
  )
}
