import company from 'store/company'
const moment = require('moment-timezone')

const UTCDateStringToLocalString = (dateString, timezone = company.city.timezone) => {
  let UTCDate = new Date(dateString)
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  return UTCDate.toLocaleDateString('en-US', options)
}

const UTCDateStringToLocalHHMM = (utcDateString, timezone = company.city.timezone) => {
  const utcDate = new Date(utcDateString)
  const UTCHours = utcDate.getUTCHours()
  const UTCMinutes = utcDate.getUTCMinutes()

  let currentDate = new Date()
  currentDate.setUTCHours(UTCHours)
  currentDate.setUTCMinutes(UTCMinutes)

  const options = {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  return currentDate.toLocaleTimeString('en-US', options)
}

const UTCScheduleToLocalHHMM = (schedules, timezone) => {
  return schedules
    .map(item => {
      return {
        start_at: UTCDateStringToLocalHHMM(item.start_at, timezone),
        end_at: UTCDateStringToLocalHHMM(item.end_at, timezone),
        day_of_week: item.day_of_week
      }
    })
}

const localHHMMToUTCString = (hhmm, timezone = company.city.timezone) => {
  const localDate = new Date()
  const [hours, minutes] = hhmm.split(':').map(Number)
  return convertByPartsToUTCDateString(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    hours,
    minutes,
    timezone
  )
}

const convertByPartsToUTCDateString = (year, month, day, hours, minutes, timezone = company.city.timezone) => {
  const localDateTime = moment.tz({
    year: year,
    month: month - 1,
    day: day,
    hour: hours,
    minute: minutes
  }, timezone)

  return localDateTime.utc().toISOString()
}

const UTCDateStringToLocalMMDDYYYY = (dateString, timezone = company.city.timezone) => {
  const dateStringInTimeZone = UTCDateStringToLocalString(dateString, timezone)
  const [month, day, year] = dateStringInTimeZone.split('/')

  return `${month}-${day}-${year}`
}

const UTCDateStringToLocalYYYYMMDD = (dateString, timezone = company.city.timezone) => {
  const dateStringInTimeZone = UTCDateStringToLocalString(dateString, timezone)
  const [month, day, year] = dateStringInTimeZone.split('/')

  return `${year}-${month}-${day}`
}

export {
  UTCScheduleToLocalHHMM,
  localHHMMToUTCString,
  UTCDateStringToLocalHHMM,
  UTCDateStringToLocalMMDDYYYY,
  UTCDateStringToLocalYYYYMMDD,
  convertByPartsToUTCDateString
}
