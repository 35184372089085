import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import i18n from 'config/i18n'

const preAuthenticate = (phone, userRole) => {
  const query = () => {
    return fetch(`${API_URL}/${userRole}/pre_authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        phone_number: phone,
        country_code: 'UA',
      })
    })
  }

  return backOffCall(query)
}

const authenticate = (resourceId, code, userRole) => {
  const query = () => {
    return fetch(`${API_URL}/${userRole}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Accept-Version': BE_VERSION,
      },
      body: JSON.stringify({
        resource_id: resourceId,
        code: code
      })
    })
  }

  return backOffCall(query)
}

export default {
  preAuthenticate,
  authenticate
}
