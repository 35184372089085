import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { loadLocales } from 'api/locales.js'
import config from 'store/config'

const backendOptions = {
  loadPath: '{{lng}}',
  request: (options, lang, payload, callback) => {
    loadLocales(lang).then((result) => {
      callback(null, {
        data: result,
        status: 200,
      })
    })
  },
}

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'ru')
}

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: config.constants.FALL_BACK_LNG,
    supportedLngs: config.constants.LOCALES,
    debug: false,
    load: 'languageOnly',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true,
    }
  })

export default i18n
