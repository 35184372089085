import { backOffCall } from 'api/api.js'
import { API_URL, BE_VERSION } from 'config/constants'
import master from 'store/master'
import i18n from 'config/i18n'

const getEvents = () => {
  const query = () => {
    return fetch(`${API_URL}/master/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token,
        'Accept-Version': BE_VERSION,
      }
    })
  }

  return backOffCall(query)
}

const createEvent = (
  {
    start_at = '',
    end_at = '',
    address = '',
    description = ''
  }) => {
  const query = () => {
    return fetch(`${API_URL}/master/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'Authorization': master.token
      },
      body: JSON.stringify({
        start_at: start_at,
        end_at: end_at,
        address: address,
        description: description
      })
    })
  }

  return backOffCall(query)
}

export {
  getEvents,
  createEvent
}
