import { React } from 'react'
import { observer } from 'mobx-react-lite'
import s from 'styles/shared/modals/VersionUpgradeModal.module.css'
import meta from 'store/meta'
import { Modal } from 'components/shared/Modal'
import imageStarGreen from 'images/star-green.svg'
import imageStarBlack from 'images/star-black.svg'
import { useTranslation } from 'react-i18next'
import { redirect } from 'react-router-dom'

export const VersionUpgradeModal = observer(() => {
  const { t } = useTranslation()

  return (
    <Modal active={ meta.isVersionUpgradeModal } setActive={ (state) => meta.isVersionUpgradeModal(state) } needCancelBtn={ false }>
      <div className={ s.versionUpgradeModal }>
        <div className={ s.orderHead }>
          <img src={imageStarGreen} className={s.starGreen} />
          <img src={imageStarBlack} className={s.starBlack} />
          <h2>{ t('version_upgrade') }</h2>
        </div>
        <div className={ s.orderBody }>
          <button className='pressedButton' onClick={ () => window.location.replace('https://play.google.com/store/apps/details?id=ua.com.uklontaxi&hl=uk') }>{ t('upgrade_btn') }</button>
        </div>
      </div>
    </Modal>
  )
})
