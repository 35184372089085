import { React, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import validationSchema from 'services/validation/schemas/company.json'
import { useValidForm } from 'services/validation/validForm'
import { useState } from 'react'
import { createCompany } from 'api/company.js'
import s from 'styles/Profile.module.css'
import { useTranslation } from 'react-i18next'
import master from 'store/master'
import meta from 'store/meta'
import company from 'store/company'
import { convertKeysToCamelCase } from 'services/helper'
import { useNavigate } from 'react-router-dom'
import { Autocomplete } from 'components/shared/Autocomplete'

export const Company = observer(({ cities }) => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [name, setName] = useState(company.name || '')
  const [address, setAddress] = useState(company.address || '')
  const [field, setField] = useState(company.field || '')
  const [cityId, setCityId] = useState(master.city?.id || '')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    setValidationErrors(validForm.validateFields({ name, field }))
    if (validForm.isValid()) {
      meta.setLoader(true)
      createCompany({
        companyType: 1,
        companyName: name,
        cityId: cityId,
        address: address,
        lat: lat,
        lng: lng,
        field: field
      }).then(data => {
        if (data?.errors?.hasOwnProperty('validation')) {
          let errors = data.errors.validation
          if (errors.hasOwnProperty('lat') || errors.hasOwnProperty('lng')) {
            delete errors.lat
            delete errors.lng
            errors.address = [t('autocomplete.error')]
          }
          setValidationErrors(convertKeysToCamelCase(data.errors.validation))
        } else {
          navigate('/company_request')
        }
        meta.setLoader(false)
      })
    }
  }

  const getAddressData = (data) => {
    setAddress(data.address)
    setLat(data.lat)
    setLng(data.lng)
  }

  useEffect(() => {
    if (cities.length && !cityId) {
      setCityId(cities[0].id)
    }
  }, [cities])

  return (
    <form>
      <div className={ s.content }>
        <div className='textOnInput'>
          <label htmlFor='name'>{ t('master.profile.create.company_name') }</label>
          <input
            id='name'
            type='text'
            onChange={ e => setName(e.target.value) }
            value={ name }
            name='name'
          />
        </div>
        <div className={ s.error }>{ validationErrors.name }</div>

        <div className='textOnInput'>
          <label htmlFor='city'>{ t('master.profile.create.city') }</label>
          <select className='city' value={ cityId ? cityId : cities[0]?.id } onChange={ e => setCityId(e.target.value) }>
            { cities.map(city => (
              <option value={ city.id } key={ city.id } >{ city.name }</option>
            ))}
          </select>
        </div>

        {/*<div className='textOnInput'>*/}
        {/*  <label htmlFor='address'>{ t('master.profile.create.address') }</label>*/}
        {/*  <input*/}
        {/*    id='address'*/}
        {/*    type='text'*/}
        {/*    onChange={ e => setAddress(e.target.value) }*/}
        {/*    value={ address }*/}
        {/*    name='address'*/}
        {/*  />*/}
        {/*</div>*/}
        <Autocomplete
          isLoaded={ meta.isMapLoaded }
          defaultValue = { address }
          sendAddressData ={ getAddressData }
        />
        <div className={ s.error }>{ validationErrors.address }</div>

        <input onChange={ e => setField(e.target.value) } value={field} name='field' type='text' placeholder={ t('master.profile.create.field') } />
        <div className={ s.error }>{ validationErrors.field }</div>

      </div>
      <button className={ `${s.nextButton} pressedButton` } onClick={ e => handleSubmit(e) }>{ master.profile ? t('master.profile.update.submit') : t('master.profile.create.submit') }</button>
    </form>

  )
})
