import './App.css'
import { React, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { ProtectedRoute } from 'components/shared/ProtectedRoute'
import { About } from 'components/About'
import { Login } from 'components/Login'
import { Orders } from 'components/Orders'
import { OrdersMonitor } from 'components/OrdersMonitor'
import { Profile } from 'components/Profile'
import { Service } from 'components/Service'
import { Kyc } from 'components/Kyc'
import { CompanyRequest } from 'components/CompanyRequest'

import company from 'store/company'
import master from 'store/master'
import config from 'store/config'
import { getProfile } from './api/master/profile'
import { getServices } from './api/master/service'
import { getKycs } from 'api/kyc'
import { getCurrentOrder } from 'api/master/order'
import { useTranslation } from 'react-i18next'
import { Loader } from './components/shared/Loader'
import { Alert } from './components/Alert'
import OrderMonitorSocket from 'store/ws/orderMonitorSocket'
import meta from 'store/meta'
import { ConfirmModal } from 'components/shared/ConfirmModal'
import { Calendar } from 'components/Calendar'
import { Payment } from 'components/Payment'
import { Bonus } from 'components/Bonus'
import { useJsApiLoader } from '@react-google-maps/api'
import { OrderConfirmedModal } from 'components/shared/modals/OrderConfirmedModal'
import { ServerDownModal } from 'components/shared/modals/ServerDownModal'
import { VersionUpgradeModal } from 'components/shared/modals/VersionUpgradeModal'
import { Referrals } from 'components/referral_program/Referrals'
import { Tasks } from 'components/tasks/Tasks'
import { Create as ReferralProgramCreate } from 'components/referral_program/Create'
import { UTCScheduleToLocalHHMM } from 'services/dateFormatter'
import { GM_LIBRARIES, GM_API_KEY } from 'config/constants'
import { PageStructure } from 'components/shared/PageStructure'

const App = observer(() => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GM_API_KEY,
    libraries: GM_LIBRARIES
  })

  useEffect(() => {
    if (master.isLoggedIn() && config.isLoaded) {
      meta.setLoader(true)

      getProfile()
        .then(data => {
          if (!data || data.hasOwnProperty('errors')) {
            meta.setLoader(false)
            master.logout()
            return navigate('/login')
          }

          i18n.changeLanguage(data.master.lang)
          master.setMasterData(data.master)
          if (data?.company?.schedules) {
            data.company.schedules = UTCScheduleToLocalHHMM(data.company.schedules, data.company.city.timezone)
          }
          company.setCompanyData(data.company)
          if (!data.master?.referral_program) {
            meta.setLoader(false)
            return navigate('/referral_program/create')
          }

          if (data.company?.company_type === 'company') {
            meta.setLoader(false)
            return navigate('/company_request')
          }

          if (!data.master?.profile) {
            meta.setLoader(false)
            return navigate('/profile')
          }
          getServices()
            .then(data => {
              company.setServices(data.services)
              meta.setLoader(false)
              if (!data.services.length) {
                return navigate('/service')
              }
            })

          getKycs()
            .then(data => {
              master.setKycs(data.kycs)
              if (data.kycs.some(kyc => kyc.approved === true)) {
                return
              }

              if (data.kycs.some(kyc => kyc.approved === null)) {
                return navigate('/kyc_request')
              }

              return navigate('/kyc')
            })
          // getCurrentOrder()
          //   .then(data => {
          //     if (data.order) {
          //       master.setCurrentOrder(data.order)
          //     }
          //     meta.setLoader(false)
          //   })
        })
      meta.setLoader(false)
    }
  }, [master.isLoggedIn(), config.isLoaded])

  useEffect(() => {
    meta.setIsMapLoaded(isLoaded)
  }, [isLoaded])

  useEffect(() => {
    master.setNavigate(navigate)
    config.loadConfig()

    const handleOnline = () => {
      meta.setIsOnline(true)
      meta.setServerDownModal(false)
    }
    const handleOffline = () => {
      meta.setIsOnline(false)
      meta.setServerDownModal(true)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    config.isLoaded ? <div className='App'>
      <ConfirmModal />
      <OrderConfirmedModal />
      <ServerDownModal />
      <VersionUpgradeModal />
      { meta.isLoading ? <Loader/> : null }
      <Alert show={ meta.showAlert } message={ meta.alertMessage } />
      <Routes>
        <Route element={<PageStructure />}>
          <Route path = '/login' element = { <ProtectedRoute isAllowed={ !master.isLoggedIn() } redirectPath='/' >  <Login /> </ProtectedRoute>} />
          <Route path = '/about' element= { <About /> } />
          <Route element = { <ProtectedRoute isAllowed={ master.isLoggedIn() } redirectPath='/login' /> }>
            <Route index element={ <OrdersMonitor /> } />
            <Route path = '/orders_monitor' element={ <OrdersMonitor /> } />
            <Route path = '/orders' element= { <Orders /> } />
            <Route path = '/profile' element= { <Profile /> } />
            <Route path = '/service' element= { <Service /> } />
            <Route path = '/kyc' element= { <Kyc /> } />
            <Route path = '/kyc_request' element= { <CompanyRequest text={'master.kyc.waiting_approval'} /> } />
            <Route path = '/calendar' element= { <Calendar /> } />
            <Route path = '/company_request' element= { <CompanyRequest /> } />
            <Route path = '/payment' element= { <Payment /> } />
            <Route path = '/bonus' element= { <Bonus /> } />
            <Route path = '/referrals' element= { <Referrals /> } />
            <Route path = '/referral_program/create' element= { <ReferralProgramCreate /> } />
            <Route path = '/tasks' element= { <Tasks /> } />
          </Route>
        </Route>
      </Routes>
    </div> : <Loader/>
  )
})

export default App
